import { TextField } from "@mui/material";

interface CustomSelectNativeProps {
	noBlank?: boolean;
	options: { value: string | number; label: string }[];
}
const SelectNative: React.FC<CustomSelectNativeProps & React.ComponentProps<typeof TextField>> = ({ noBlank, options, ...otherProps }) => {
	return (
		<TextField
			fullWidth={true}
			select
			slotProps={{
				select: {
					native: true,
				},
			}}
			variant="standard"
			{...otherProps}
		>
			{noBlank !== true && <option value=""></option>}
			{options.map((option) => (
				<option key={option.value} value={option.value}>
					{option.label}
				</option>
			))}
		</TextField>
	);
};

export default SelectNative;
