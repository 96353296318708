import { Alert, Button, Chip, Grid2 as Grid, Icon, Typography } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid";
import SelectWorkorder from "~count/components/SelectWorkorder";
import SetDownAdd from "~count/components/SetDownAdd";
import SetDownCurrent from "~count/components/SetDownCurrent";
import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { AuthContext } from "~/auth";
import ModalDialog from "~/components/ModalDialog";
import SelectCard from "~/components/select/SelectCard";
import SpacedGridContainer from "~/components/SpacedGridContainer";
import SpacedGridItem from "~/components/SpacedGridItem";
import wsId from "~/helpers/wsId";
import { useAppDispatch, useAppSelector } from "~/hooks/reduxHooks";
import { getWorkOrderChipColor, getWorkOrderStatus } from "~/pages/Count/helpers/workOrderStatusMaps";
import { useWorkOrdersQuery, WorkOrdersQueryVariables, WorkOrderStatus } from "~/services/graphql";
import { JobDocument } from "~/services/graphql";
import {
	closeAddSetDown,
	openAddSetDown,
	updateSetDownCount,
	updateSetDownJob,
	updateSetDownLocationId,
	updateSetDownWorkorder,
} from "~/store/count/setDownSlice";
import { addQueueItem } from "~/store/queueSlice";

interface SetDownProps {
	jobs: JobDocument[];
}

const SetDown = ({ jobs }: SetDownProps) => {
	const { workOrderID } = useParams();
	const authContext = useContext(AuthContext);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const addingSetDown = useAppSelector((state) => state.count.setDown.addingSetDown);

	const queueItems = useAppSelector((state) =>
		state.queue.queueItems.filter((item) => item.groupKey === (workOrderID || "qqqqqqqqqqq") && item.operationName === "SetDown")
	);
	console.log("queueItems", queueItems);
	const setDownQuantity = queueItems.reduce((acc, item) => acc + (item.variables as { count: number }).count, 0);

	const jobIds = jobs.map((job) => job.id);

	const workOrdersVariables: WorkOrdersQueryVariables = {
		first: 50,
		jobId: jobIds,
		tenantId: authContext.company?.abbreviation || "NOTFOUND",
		workOrderStatus: [WorkOrderStatus.New, WorkOrderStatus.InProgress, WorkOrderStatus.Closed],
	};

	useEffect(() => {
		localStorage.setItem("lastPage", "count/setdown");
	}, []);

	const { data: workOrders, error, isLoading } = useWorkOrdersQuery(workOrdersVariables, { pollingInterval: 30000 });

	if (isLoading) return <div>Loading work orders...</div>;

	if (error) return <div>Error: Getting WorkOrders</div>;

	const handleAddSetDown = (count: number, locationId: string) => {
		if (count <= 0) {
			return;
		}
		if (!location) {
			return;
		}
		if (!workOrderID) {
			return;
		}
		// do something
		dispatch(
			addQueueItem({
				displayData: { count: count, job: job, location: locationId, workOrder: workOrder },
				groupKey: workOrderID,
				id: wsId(),
				operationName: "SetDown",
				status: "pending",
				variables: { count: count, locationId: locationId },
			})
		);
		dispatch(updateSetDownCount(0));
		dispatch(updateSetDownLocationId(null));
		dispatch(closeAddSetDown());
	};

	const handleWorkOrderChange = (workOrderId: GridRowId, jobId: string) => {
		console.log("handleWorkOrderChange", workOrderId, jobId);
		navigate("/count/setdown/" + workOrderId);
	};

	const handleJobClear = () => {
		dispatch(updateSetDownJob(null));
		dispatch(updateSetDownWorkorder(null));
		navigate("/count/setdown");
	};

	//find if there is a matching workorder based on param passed in
	const workOrder = workOrders?.workOrders?.nodes?.find((wo) => wo.id === (workOrderID || ""));
	const job = jobs.find((job) => job.id === workOrder?.jobId);

	if (!job || !workOrder) {
		return (
			<SpacedGridContainer>
				<SpacedGridItem maxCols={1} smMargin>
					<Typography variant="h5">Set Down - Select Workorder</Typography>
				</SpacedGridItem>
				<SelectWorkorder handleInputChange={handleWorkOrderChange} jobs={jobs} workOrderStatuses={[WorkOrderStatus.InProgress, WorkOrderStatus.Closed]} />
				<SpacedGridItem maxCols={1} smMargin></SpacedGridItem>
			</SpacedGridContainer>
		);
	}

	///const totalSetDowns = workorder?.SetDowns?.reduce((acc, setDown) => acc + setDown.Count, 0) || 0;

	return (
		<>
			<SpacedGridContainer>
				<SpacedGridItem maxCols={1}>
					<SelectCard>
						<Grid alignItems="stretch" container direction="row" spacing={1}>
							<Grid size={8}>
								<Typography color="textSecondary" component="span" variant="inherit">
									<Button
										aria-label="back"
										endIcon={<Icon>arrow_drop_down</Icon>}
										onClick={() => {
											handleJobClear();
										}}
										sx={{ padding: 0 }}
										variant="text"
									>
										Workorder: {workOrder.erpId}{" "}
									</Button>
								</Typography>
							</Grid>
							<Grid size={4} style={{ textAlign: "end" }}>
								<Chip color={getWorkOrderChipColor(workOrder.workOrderStatus)} label={getWorkOrderStatus(workOrder.workOrderStatus)} size="small" />
							</Grid>
							<Grid size={12} sx={{ textAlign: "center" }}>
								<Typography color="textPrimary" component="span" variant="h5">
									{job.item.name}
								</Typography>
							</Grid>

							<Grid size={8}>
								<Typography color="textSecondary" component="span" variant="inherit">
									UPC:
								</Typography>{" "}
								{job.item.upc}
							</Grid>
							<Grid size={4} style={{ textAlign: "end" }}>
								<Typography color="textSecondary" component="span" variant="inherit">
									Target:
								</Typography>{" "}
								{`${workOrder.containerQuantity}`}
							</Grid>
						</Grid>
					</SelectCard>
				</SpacedGridItem>
				<SpacedGridItem maxCols={1} smMargin>
					<Grid alignItems="stretch" container direction="row" spacing={1}>
						<Grid size="grow">
							<Typography color="textPrimary" component="span" variant="h6">
								Set Down: {setDownQuantity}
							</Typography>
						</Grid>
						<Grid>
							<Typography color="textPrimary" component="span" variant="h6">
								Produced: {workOrder.quantityProduced ? workOrder.quantityProduced : "?"}
							</Typography>
						</Grid>
					</Grid>
				</SpacedGridItem>
				<SpacedGridItem maxCols={1} smMargin>
					<Button color="primary" fullWidth onClick={() => dispatch(openAddSetDown())} variant="contained">
						Add Set Down
					</Button>
				</SpacedGridItem>
				<SpacedGridItem maxCols={1} smMargin>
					<SetDownCurrent />
				</SpacedGridItem>
				<SpacedGridItem maxCols={1} smMargin>
					{workOrder.quantityProduced > 0 && setDownQuantity === workOrder.quantityProduced ? (
						<Button color="primary" fullWidth onClick={() => void 0} variant="contained">
							Complete Workorder
						</Button>
					) : (
						<>{setDownQuantity > 0 && <Alert severity="info">Set Down quantity must match the Produced quantity before completing the workorder.</Alert>}</>
					)}
				</SpacedGridItem>
			</SpacedGridContainer>
			<ModalDialog
				close={() => {
					return;
				}}
				open={addingSetDown}
				title="Add SetDown"
			>
				<SetDownAdd handleAddSetDown={handleAddSetDown} />
			</ModalDialog>
		</>
	);
};

export default SetDown;
