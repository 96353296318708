import CountClipboard from "~count/CountClipboard";
import CountMenu from "~count/CountMenu";
import ProductionLine from "~count/ProductionLine";
import ProductionLines from "~count/ProductionLines";
import SetDown from "~count/SetDown";
import { useContext } from "react";
import { Route, Routes } from "react-router-dom";

import { AuthContext } from "~/auth";
import { useAppSelector } from "~/hooks/reduxHooks";
import { JobsQueryVariables, JobStatus, useJobsQuery } from "~/services/graphql";

const Count = () => {
	const authContext = useContext(AuthContext);
	const site = useAppSelector((state) => state.site);

	//const { allJobs, isLoading, isError } = useJobsPaginatedQuery();
	const jobsVariables: JobsQueryVariables = {
		first: 50,
		jobStatus: [JobStatus.Released, JobStatus.InProduction],
		locationId: site.location?.id || "NOTFOUND",
		tenantId: authContext.company?.abbreviation || "NOTFOUND",
	};
	const { data, error, isLoading } = useJobsQuery(jobsVariables, { pollingInterval: 30000 });
	// If jobs are loading or there's an error, handle that state
	if (isLoading) return <div>Loading jobs...</div>;
	if (error) return <div>Error: Getting Jobs</div>;

	return (
		<>
			<Routes>
				<Route element={<CountMenu />} path="" />
				<Route element={<CountClipboard />} path="clipboard" />
				<Route element={<ProductionLine jobs={data?.jobs?.nodes || []} />} path="line/:lineNumber" />
				<Route element={<ProductionLines jobs={data?.jobs?.nodes || []} />} path="lines" />
				<Route element={<SetDown jobs={data?.jobs?.nodes || []} />} path="setdown/:workOrderID?" />
			</Routes>
		</>
	);
};

export default Count;
