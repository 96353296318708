import { Alert, Icon } from "@mui/material";
import { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import RequireAuth from "~/auth/RequireAuth";
import RequireSite from "~/components/RequireSite";
import { useAppDispatch } from "~/hooks/reduxHooks";
import Account from "~/pages/Account";
import Count from "~/pages/Count";
import ErrorLog from "~/pages/ErrorLog";
import Forbidden from "~/pages/Forbidden";
import Home from "~/pages/Home";
import Items from "~/pages/items";
import Material from "~/pages/Material";
import OAuthRedirect from "~/pages/OAuthRedirect";
import Settings from "~/pages/Settings";
import { hideTopBar, showTopBar } from "~/store/layoutSlice";

const Router = () => {
	const location = useLocation();
	const dispatch = useAppDispatch();
	const lastPage = localStorage.getItem("lastPage");

	if (location.pathname === "/" && lastPage) {
		return <Navigate replace={true} to={`${lastPage}`} />;
	}

	useEffect(() => {
		if (location.pathname.startsWith("/count/counting/")) {
			dispatch(hideTopBar());
		} else {
			dispatch(showTopBar());
		}
	}, [location, dispatch]);
	return (
		<Routes>
			<Route element={<OAuthRedirect />} path="/oauth-redirect" />
			<Route element={<Forbidden />} path="/Forbidden" />
			<Route element={<ErrorLog />} path="/errorLog" />
			<Route
				element={
					<RequireAuth>
						<RequireSite required={false}>
							<Settings />
						</RequireSite>
					</RequireAuth>
				}
				path="/settings"
			/>
			<Route
				element={
					<RequireAuth>
						<RequireSite required={false}>
							<Account />
						</RequireSite>
					</RequireAuth>
				}
				path="/account"
			/>
			<Route
				element={
					<RequireAuth>
						<RequireSite>
							<Items />
						</RequireSite>
					</RequireAuth>
				}
				path="/items"
			/>
			<Route
				element={
					<RequireAuth>
						<RequireSite>
							<Count />
						</RequireSite>
					</RequireAuth>
				}
				path="/count/*"
			/>
			<Route
				element={
					<RequireAuth>
						<RequireSite>
							<Material />
						</RequireSite>
					</RequireAuth>
				}
				path="/material/*"
			/>
			<Route
				element={
					<RequireAuth>
						<RequireSite required={false}>
							<Home />
						</RequireSite>
					</RequireAuth>
				}
				path="/home"
			/>
			<Route element={<Navigate to="/home" />} path="/" />
			<Route
				element={
					<Alert icon={<Icon>construction</Icon>} severity="warning" sx={{ margin: "1em" }}>
						This page does not exist yet.
					</Alert>
				}
				path="*"
			/>
		</Routes>
	);
};

export default Router;
