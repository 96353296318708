import { Grid2 as Grid, Typography } from "@mui/material";
import { GridColDef, GridColumnVisibilityModel } from "@mui/x-data-grid";
import { Fragment } from "react";

import HeaderCard from "~/components/HeaderCard";
import SpacedGridItem from "~/components/SpacedGridItem";
import { useAppSelector } from "~/hooks/reduxHooks";
import { MaterialItemDocument } from "~/services/graphql";

// type columns so that the field values are keys of MaterialItemDocument

const columns: GridColDef[] = [
	{ field: "id", flex: 1, headerName: "id" },
	{ field: "description", flex: 1, headerName: "Description" },
	{ field: "erpId", flex: 1, headerName: "ERP ID" },
	{ field: "name", flex: 1, headerName: "Name" },
	{ field: "status", flex: 1, headerName: "Status" },
	{ field: "tenantId", flex: 1, headerName: "Tenant ID" },
	{ field: "created", flex: 1, headerName: "Created" },
	{ field: "updated", flex: 1, headerName: "Updated" },
];

//type this so I can use strings as index

const columnVisibilityModel: GridColumnVisibilityModel = {
	created: false,
	description: true,
	erpId: true,
	id: false,
	name: true,
	status: true,
	tenantId: false,
	updated: false,
};

const MaterialItem = () => {
	const materialItem = useAppSelector((state) => state.material.materialItem.materialItemDocument);

	if (!materialItem) return null;
	return (
		<>
			<SpacedGridItem maxCols={1} smMargin>
				<HeaderCard>
					{columns.map((col, j) => {
						if (columnVisibilityModel && columnVisibilityModel[col.field] === false) return null;
						return (
							<Fragment key={j}>
								<Grid size={4} style={{ textAlign: "right" }}>
									<Typography color="textSecondary" component="span" variant="inherit">
										{col.headerName} :
									</Typography>
								</Grid>
								<Grid size={8}>{String(materialItem[col.field as keyof MaterialItemDocument]) || ""}</Grid>
							</Fragment>
						);
					})}
				</HeaderCard>
			</SpacedGridItem>
		</>
	);
};

export default MaterialItem;
