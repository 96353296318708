const savedValue = (name: string, update?: string | null | number | boolean): string => {
	try {
		const saved = JSON.parse(localStorage.getItem("savedValues") || "{}");
		if (name && typeof update !== "undefined") {
			//if update is empty string, delete the value
			//otherwise, update the value
			if (update === "") {
				delete saved[name];
			} else {
				saved[name] = update;
			}
			localStorage.setItem("savedValues", JSON.stringify(saved));
		}
		return saved[name] || "";
	} catch (e) {
		return "";
	}
};

export default savedValue;
