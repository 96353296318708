import { useEffect, useState, useContext, useRef } from "react";

import { useLocationsQuery } from "~/services/endpoints";

//material-ui
import { Alert, Autocomplete, FilterOptionsState, TextField } from "@mui/material";
import { ClientError } from "graphql-request";

import { AuthContext } from "~/auth";
import { anyToString } from "~/helpers/toString";
import { useLogError } from "~/hooks/useLogError";
import { LocationDocument, LocationType } from "~/services/graphql";

interface SelectSiteProps {
	handleInputChange: (val: LocationDocument | null) => void;
	initialValue?: LocationDocument;
}

const SelectSite = ({ handleInputChange, initialValue }: SelectSiteProps) => {
	const [sites, setSites] = useState<LocationDocument[]>([]);
	const [hasNextPage, setHasNextPage] = useState(true);
	const [cursor, setCursor] = useState<string | null>(null);
	const authContext = useContext(AuthContext);
	const [logError] = useLogError();

	const [acVal, setAcVal] = useState<LocationDocument | null>(initialValue || null);
	const [acOpen, setAcOpen] = useState(false);

	const { data, error, isLoading, refetch } = useLocationsQuery(
		{ after: cursor, first: 50, locationTypes: [LocationType.Site], tenantId: authContext.company?.abbreviation || "NOTFOUND" },
		{
			skip: !hasNextPage,
		}
	);

	useEffect(() => {
		if (data && data.locations && data.locations.nodes && data.locations.nodes.length > 0) {
			const nodes: LocationDocument[] = data.locations.nodes || [];
			setSites((prevData) => [...prevData, ...nodes] as LocationDocument[]);
			setHasNextPage(data.locations.pageInfo.hasNextPage);
			setCursor(data.locations.pageInfo.endCursor || null);
		}
	}, [data]);

	useEffect(() => {
		if (hasNextPage) {
			refetch();
		}
	}, [hasNextPage, refetch]);

	const acInput = useRef<HTMLInputElement>(null);

	const acFilter = (options: LocationDocument[], state: FilterOptionsState<LocationDocument>) => {
		const result = options.filter((option) => `${option.name}`.toLowerCase().indexOf(state.inputValue.toLowerCase()) > -1);
		if (result.length === 1) {
			setAcVal(result[0]);
			handleInputChange(result[0]);
			setAcOpen(false);
		}
		return result;
	};

	if (isLoading) return <p>Loading...</p>;

	if (error) {
		const clientError = error as ClientError;
		const errorMessage = `Error: ${anyToString(clientError)}`;
		logError({ code: "SelectSiteError", detail: clientError, message: anyToString(clientError.message) });
		return <Alert severity="error">{errorMessage}</Alert>;
	}

	return (
		<Autocomplete
			filterOptions={acFilter}
			getOptionLabel={(option) => (option.name ? `${option.name}` : "")}
			id="upc-input"
			isOptionEqualToValue={(option, value) => option.id === value.id}
			onChange={(_e, v) => {
				setAcVal(v);
				handleInputChange(v);
			}}
			onClose={() => setAcOpen(false)}
			onOpen={() => setAcOpen(true)}
			open={acOpen}
			options={sites}
			renderInput={(params) => <TextField {...params} autoFocus={true} label="Site" ref={acInput} variant="outlined" />}
			style={{ width: "100%" }}
			value={acVal}
		/>
	);
};

export default SelectSite;
