import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { LocationDocument } from "~/services/graphql";

// Define a type for the slice state
interface SiteState {
	location: LocationDocument | undefined;
	required?: boolean;
}

const initialState: SiteState = {
	location: undefined,
	required: true,
};

export const siteSlice = createSlice({
	initialState,
	name: "site",
	reducers: {
		updateSite: (state, action: PayloadAction<LocationDocument | null>) => {
			if (!action.payload || action.payload === null) {
				delete state.location;
			} else {
				if (!state.location) {
					state.location = action.payload;
				} else {
					Object.assign(state.location, action.payload);
				}
			}
		},
		updateSiteRequired: (state, action: PayloadAction<boolean>) => {
			state.required = action.payload;
		},
	},
});

export const { updateSite, updateSiteRequired } = siteSlice.actions;

export default siteSlice.reducer;
