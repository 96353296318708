import { useContext, useEffect, useState } from "react";

import { AuthContext } from "~/auth";
import { useLazyLocationsQuery } from "~/services/endpoints";
import { LocationDocument, LocationType } from "~/services/graphql";

const useLocationsPaginatedQuery = (locationTypes: LocationType[]) => {
	const [cursor, setCursor] = useState<string | null | undefined>();
	const [allLocations, setAllLocations] = useState<LocationDocument[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const [trigger, { isError }] = useLazyLocationsQuery();
	const authContext = useContext(AuthContext);

	useEffect(() => {
		const fetchLocations = async () => {
			const result = await trigger({
				after: cursor,
				first: 50,
				locationTypes: locationTypes,
				tenantId: authContext.company?.abbreviation || "NOTFOUND",
			}).unwrap();
			if (result.locations && result.locations.nodes && result.locations.nodes.length > 0) {
				const nodes: LocationDocument[] = result.locations.nodes || [];
				setAllLocations((prevItems) => [...prevItems, ...nodes]);
				if (result.locations.pageInfo.hasNextPage) {
					setCursor(result.locations.pageInfo.endCursor || null);
				} else {
					setCursor(null);
				}
			} else {
				setCursor(null);
			}
		};
		if (cursor !== null) {
			fetchLocations();
		} else {
			setIsLoading(false);
		}
	}, [cursor, trigger]);

	const loadMore = () => {
		if (cursor) {
			setCursor(cursor);
		}
	};

	return { allLocations, isError, isLoading, loadMore };
};

export default useLocationsPaginatedQuery;
