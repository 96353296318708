import { Box, Button, Collapse, Grid2 as Grid, Icon, TextField, Typography } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid";
import ScreenPaper from "~count/components/ScreenPaper";
import SelectWorkorder from "~count/components/SelectWorkorder";
import { useConfirm } from "material-ui-confirm";
import { useContext, useState } from "react";
import { Navigate, useParams } from "react-router-dom";

import { AuthContext } from "~/auth";
import SpacedGridContainer from "~/components/SpacedGridContainer";
import SpacedGridItem from "~/components/SpacedGridItem";
import { stringFormatDateTime } from "~/helpers/timestampFormat";
import wsId from "~/helpers/wsId";
import { useAppDispatch, useAppSelector } from "~/hooks/reduxHooks";
import { useSnacks } from "~/providers/SnackProvider";
import {
	JobDocument,
	useChangeWorkOrderQuantityProducedMutation,
	useChangeWorkOrderStatusMutation,
	useSetWorkOrderLineNumberMutation,
	useResetWorkOrderQuantityProducedMutation,
	useWorkOrdersQuery,
	WorkOrderDocument,
	WorkOrdersQueryVariables,
	WorkOrderStatus,
} from "~/services/graphql";
import { addQueueItem } from "~/store/queueSlice";
interface ProductionLineProps {
	jobs: JobDocument[];
}

const ProductionLine = ({ jobs }: ProductionLineProps) => {
	const confirm = useConfirm();
	const dispatch = useAppDispatch();
	const [openSnack] = useSnacks();
	const authContext = useContext(AuthContext);
	const { lineNumber } = useParams();
	const productionLineNumber = Number(lineNumber);
	const numberOfProductionLines = useAppSelector((state) => state.settings.numberOfProductionLines);

	const [detailsOpen, setDetailsOpen] = useState(false);
	const [count, setCount] = useState("");

	const jobIds = jobs.map((job) => job.id);

	const [changeWorkOrderQuantityProducedMutation] = useChangeWorkOrderQuantityProducedMutation();
	const [changeWorkOrderStatusMutation] = useChangeWorkOrderStatusMutation();
	const [setWorkOrderLineNumberMutation] = useSetWorkOrderLineNumberMutation();
	const [resetWorkOrderQuantityProducedMutation] = useResetWorkOrderQuantityProducedMutation();

	const postCount = (workOrderId: string, j: JobDocument, w: WorkOrderDocument) => {
		confirm({
			cancellationButtonProps: { variant: "outlined" },
			confirmationButtonProps: { variant: "outlined" },
			title: "Are you sure you want to post the current count?",
		})
			.then(() => {
				const quantityProducedVariables = {
					input: {
						jobId: j.id,
						quantityProduced: Number(count),
						tenantId: authContext.company?.abbreviation || "NOTFOUND",
						workOrderId: workOrderId,
					},
				};
				changeWorkOrderQuantityProducedMutation(quantityProducedVariables).then((result) => {
					console.log("changeWorkOrderQuantityProducedMutation", result);
					if (result.error || !result.data || result.data.changeWorkOrderQuantityProduced?.errors) {
						openSnack("Error posting count", "error");
						return;
					}
					const quantityProducedQueueItem = {
						displayData: { count: count, job: j, workOrder: w },
						groupKey: workOrderId,
						id: wsId(),
						operationName: "changeWorkOrderQuantityProducedMutation",
						status: "completed",
						variables: quantityProducedVariables,
					};
					dispatch(addQueueItem(quantityProducedQueueItem));

					const statusChangeVariables = {
						input: {
							jobId: j.id,
							status: WorkOrderStatus.Closed,
							tenantId: authContext.company?.abbreviation || "NOTFOUND",
							workOrderId: workOrderId,
						},
					};

					changeWorkOrderStatusMutation(statusChangeVariables).then((result) => {
						if (result.error || !result.data || result.data.changeWorkOrderStatus?.errors) {
							openSnack("Error updating status", "error");
							return;
						}
					});
					const changeStatusQueueItem = {
						displayData: { count: count, job: j, workOrder: w },
						groupKey: workOrderId,
						id: wsId(),
						operationName: "changeWorkOrderStatusMutation",
						status: "completed",
						variables: statusChangeVariables,
					};
					dispatch(addQueueItem(changeStatusQueueItem));
				});
			})
			.catch(() => {});
	};

	const cancelCount = () => {
		confirm({
			cancellationButtonProps: { variant: "outlined" },
			cancellationText: "Do Not Cancel",
			confirmationButtonProps: { color: "error", variant: "outlined" },
			confirmationText: "Cancel This Count",
			title: "Are you sure you want to cancel the current count? Current progress will be lost.",
		})
			.then(() => {
				console.log("cancelCount");
				if (workOrder?.quantityProduced) {
					resetWorkOrderQuantityProducedMutation({
						input: {
							jobId: jobIds[0],
							tenantId: authContext.company?.abbreviation || "NOTFOUND",
							workOrderId: workOrder?.id || "",
						},
					}).then((result) => {
						if (result.error || !result.data || result.data.resetWorkOrderQuantityProduced?.errors) {
							openSnack("Error resetting count", "error");
							return;
						}
						const statusChangeVariables = {
							input: {
								jobId: jobIds[0],
								status: WorkOrderStatus.New,
								tenantId: authContext.company?.abbreviation || "NOTFOUND",
								workOrderId: workOrder?.id || "",
							},
						};
						changeWorkOrderStatusMutation(statusChangeVariables).then((result) => {
							if (result.error || !result.data || result.data.changeWorkOrderStatus?.errors) {
								openSnack("Error updating status", "error");
								return;
							}
						});
					});
				}
			})
			.catch(() => {});
	};

	const handleWorkOrderSelect = (workOrderId: GridRowId, jobId: string) => {
		setWorkOrderLineNumberMutation({
			input: {
				jobId: jobId,
				lineNumber: productionLineNumber,
				tenantId: authContext.company?.abbreviation || "NOTFOUND",
				workOrderId: String(workOrderId),
			},
		}).then((result) => {
			if (result.error || !result.data || result.data.setWorkOrderLineNumber?.errors) {
				openSnack("Error assigning Line", "error");
				return;
			}
			const statusChangeVariables = {
				input: {
					jobId: jobId,
					status: WorkOrderStatus.InProgress,
					tenantId: authContext.company?.abbreviation || "NOTFOUND",
					workOrderId: String(workOrderId),
				},
			};
			changeWorkOrderStatusMutation(statusChangeVariables).then((result) => {
				if (result.error || !result.data || result.data.changeWorkOrderStatus?.errors) {
					openSnack("Error updating status", "error");
					return;
				}
			});
		});
	};

	const workOrdersVariables: WorkOrdersQueryVariables = {
		first: 50,
		jobId: jobIds,
		tenantId: authContext.company?.abbreviation || "NOTFOUND",
		workOrderStatus: [WorkOrderStatus.New, WorkOrderStatus.InProgress],
	};

	const { data: workOrders, error, isLoading } = useWorkOrdersQuery(workOrdersVariables, { pollingInterval: 30000 });

	if (isLoading) return <div>Loading work orders...</div>;

	if (error) return <div>Error: Getting WorkOrders</div>;
	//if (error) return <Alert severity="info">{error.message}</Alert>;

	//check for valid production line
	if (!productionLineNumber || productionLineNumber > numberOfProductionLines) {
		return <Navigate to="/count/lines" />;
	}

	//find if there is a workorder assigned to a line
	const workOrder = workOrders?.workOrders?.nodes?.find((wo) => wo.lineNumber === productionLineNumber && wo.workOrderStatus === WorkOrderStatus.InProgress);
	const job = jobs.find((job) => job.id === workOrder?.jobId);

	if (!job || !workOrder) {
		return (
			<SpacedGridContainer>
				<SpacedGridItem maxCols={1} smMargin>
					<Typography variant="h5">Line {productionLineNumber} - Select Workorder</Typography>
				</SpacedGridItem>
				<SelectWorkorder handleInputChange={handleWorkOrderSelect} jobs={jobs} workOrderStatuses={[WorkOrderStatus.New, WorkOrderStatus.InProgress]} />
			</SpacedGridContainer>
		);
	}

	return (
		<>
			<Grid alignItems="stretch" container spacing="1vh">
				<Grid size={12}>
					<ScreenPaper>
						<Grid container>
							<Grid size="grow">
								<Typography variant="h5">Line {productionLineNumber}</Typography>
							</Grid>
							<Grid>
								<Typography variant="h5">WO# {workOrder.erpId}</Typography>
							</Grid>
						</Grid>
					</ScreenPaper>
				</Grid>
				<Grid size={12}>
					<ScreenPaper sx={{ overflow: "auto" }}>
						<Grid container spacing={1}>
							<Grid size="grow">
								<Typography variant="h5">
									<Box
										sx={{
											display: "flex",
											flexDirection: "row",
											justifyContent: "space-between",
										}}
									>
										<Box>{job.item.name || "-"}</Box>
									</Box>
								</Typography>
							</Grid>

							<Grid>
								<Button color="secondary" onClick={() => setDetailsOpen(!detailsOpen)} size="small" variant="text">
									Details {detailsOpen ? <Icon>expand_less</Icon> : <Icon>expand_more</Icon>}
								</Button>
							</Grid>
						</Grid>
						<Collapse in={detailsOpen}>
							<SpacedGridContainer mt={3}>
								<SpacedGridItem>
									Job ID: <b>{job.erpId || "-"}</b>
								</SpacedGridItem>
								<SpacedGridItem>
									Started: <b>{stringFormatDateTime(String(workOrder.startDate)) || "-"}</b>
								</SpacedGridItem>
								<SpacedGridItem>
									{" "}
									Created: <b>{stringFormatDateTime(String(workOrder.created)) || "-"}</b>
								</SpacedGridItem>
								<SpacedGridItem>
									<Button color="secondary" onClick={cancelCount} variant="contained">
										Cancel Count
									</Button>
								</SpacedGridItem>
							</SpacedGridContainer>
						</Collapse>
					</ScreenPaper>
				</Grid>
				<Grid size={12}>
					<ScreenPaper>
						<Typography variant="h6">Target</Typography>
						<div className="countbox">{workOrder.containerQuantity}</div>
					</ScreenPaper>
				</Grid>
				<Grid size={12}>
					<ScreenPaper>
						<Grid alignItems="stretch" container spacing="1vh">
							<Grid size={12}>
								<TextField fullWidth label="Count" onChange={(e) => setCount(e.target.value)} type="text" value={count}></TextField>
							</Grid>
							<Grid size={12} textAlign="center">
								<Button color="primary" fullWidth onClick={() => postCount(workOrder.id, job, workOrder)} variant="contained">
									Post Count
								</Button>
							</Grid>
						</Grid>
					</ScreenPaper>
				</Grid>
			</Grid>
		</>
	);
};

export default ProductionLine;
