import { GridColDef } from "@mui/x-data-grid";
import MaterialItem from "~material/components/MaterialItem";
import { useEffect, useState } from "react";
import { useContext } from "react";

import { AuthContext } from "~/auth/AuthProvider";
import DataCardGrid from "~/components/DataCardGrid";
import LocationName from "~/components/LocationName";
import SpacedGridContainer from "~/components/SpacedGridContainer";
import SpacedGridItem from "~/components/SpacedGridItem";
import { useAppDispatch, useAppSelector } from "~/hooks/reduxHooks";
import { MaterialItemLocationQuantitiesQueryVariables, MaterialItemLocationQuantityDocument, useMaterialItemLocationQuantitiesQuery } from "~/services/graphql";
import { openMaterialItemEdit, setMaterialItemLocationQuantity } from "~/store/material/materialItemSlice";

const columns: GridColDef[] = [
	{ field: "id", flex: 1, headerName: "id" },
	{
		field: "locationId",
		flex: 1,
		headerName: "location",
		renderCell: (params) => {
			return <LocationName locationId={params.row.locationId} />;
		},
	},
	{ field: "materialItemId", flex: 1, headerName: "materialItemId" },
	{ field: "quantity", flex: 1, headerName: "quantity" },
	{ field: "tenantId", flex: 1, headerName: "tenantId" },
	{
		field: "updated",
		flex: 1,
		headerName: "updated",
		renderCell: (params) => {
			return params.row.updated ? new Date(params.row.updated).toLocaleString() : "";
		},
	},
];

const cardColumnsRender = [
	{
		field: "locationId",
		render: (row: unknown) => {
			const materialItemLocationQuantity = row as MaterialItemLocationQuantityDocument;
			return <LocationName locationId={materialItemLocationQuantity.locationId} />;
		},
	},
	{
		field: "updated",
		render: (row: unknown) => {
			const materialItemLocationQuantity = row as MaterialItemLocationQuantityDocument;
			return materialItemLocationQuantity.updated ? new Date(String(materialItemLocationQuantity.updated)).toLocaleString() : "";
		},
	},
];

const columnVisibilityModel = {
	id: false,
	locationId: true,
	materialItemId: false,
	quantity: true,
	tenantId: false,
	updated: true,
};

const MaterialItemLocationQuantities = () => {
	const authContext = useContext(AuthContext);

	const dispatch = useAppDispatch();
	const materialItem = useAppSelector((state) => state.material.materialItem.materialItemDocument);
	const siteLocationId = useAppSelector((state) => state.site.location?.id);

	const handleSelectMaterialItemLocationQuantity = (row: unknown) => {
		const m = row as MaterialItemLocationQuantityDocument;
		if (!m.id) {
			const newMaterialItemLocationQuantity: MaterialItemLocationQuantityDocument = {
				id: "",
				locationId: "",
				materialItemId: materialItem?.id || "",
				quantity: -1,
				siteLocationId: siteLocationId || "",
				tenantId: authContext.company?.abbreviation || "NOTFOUND",
				updated: new Date().toISOString(),
			};
			dispatch(setMaterialItemLocationQuantity(newMaterialItemLocationQuantity));
		} else {
			dispatch(setMaterialItemLocationQuantity(m));
		}
		dispatch(openMaterialItemEdit());
		return;
	};
	const [materialItemLocationQuantitiesVariables, setMaterialItemLocationQuantitiesVariables] = useState<MaterialItemLocationQuantitiesQueryVariables>({
		first: 100,
		tenantId: authContext.company?.abbreviation || "NOTFOUND",
		where: {
			materialItemId: { eq: "" },
			quantity: { gt: 0 },
			siteLocationId: { eq: siteLocationId || "" },
		},
	});

	useEffect(() => {
		console.log("useEffect materialItem", JSON.stringify(materialItem));
		setMaterialItemLocationQuantitiesVariables({
			...materialItemLocationQuantitiesVariables,
			where: {
				materialItemId: { eq: String(materialItem?.id) },
				quantity: { gt: 0 },
				siteLocationId: { eq: siteLocationId || "" },
			},
		});
	}, [materialItem?.id]);

	const {
		data: materialItemLocationQuantities,
		error: materialItemLocationQuantitiesError,
		isLoading: materialItemLocationQuantitiesLoading,
	} = useMaterialItemLocationQuantitiesQuery(materialItemLocationQuantitiesVariables, { pollingInterval: 30000 });

	if (materialItemLocationQuantitiesLoading) return <div>Loading material items...</div>;

	if (materialItemLocationQuantitiesError) return <div>Error: Getting material items</div>;

	return (
		<>
			<SpacedGridContainer>
				<MaterialItem />
				<SpacedGridItem maxCols={1}>
					<DataCardGrid
						cardColumnsRender={cardColumnsRender}
						columnVisibilityModel={columnVisibilityModel}
						columns={columns}
						onSelect={handleSelectMaterialItemLocationQuantity}
						options={{ hidePagination: true, hideToolbar: true, showAddNew: true }}
						rows={materialItemLocationQuantities?.materialItemLocationQuantities?.nodes || []}
						title="Material Item Location Quantities"
					/>
				</SpacedGridItem>
			</SpacedGridContainer>
		</>
	);
};

export default MaterialItemLocationQuantities;
