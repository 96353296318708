import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";

export const useSnacks = (): [
	(
		msg: React.ReactNode,
		variant?: "default" | "error" | "success" | "warning" | "info",
		persist?: boolean,
		action?: (key: string | number | undefined) => React.ReactNode
	) => string | number,
	(key: string | number) => void
] => {
	const { closeSnackbar, enqueueSnackbar } = useSnackbar();

	const openSnack = (
		msg: React.ReactNode,
		variant: "default" | "error" | "success" | "warning" | "info" | undefined = "default",
		persist: boolean = false,
		action: (key: string | number | undefined) => React.ReactNode = () => null
	): string | number => {
		if (!action && persist) {
			action = (key: string | number | undefined) => (
				<Button
					color="inherit"
					onClick={() => {
						closeSnackbar(key);
					}}
					variant="outlined"
				>
					X
				</Button>
			);
		}

		return enqueueSnackbar(msg, {
			action: action,
			persist: persist,
			variant: variant,
		});
	};

	const closeSnack = (key: string | number) => closeSnackbar(key);

	return [openSnack, closeSnack];
};
