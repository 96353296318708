import { Icon, List, ListItem, ListItemButton, ListItemIcon, ListItemText, styled } from "@mui/material";
import { Link, LinkProps } from "react-router-dom";

//create styled Link
const MenuLink = styled(Link)<LinkProps>(({ theme }) => ({
	color: theme.palette.text.primary,
	padding: "10px 0 10px 0",
	textDecoration: "none",
	width: "100%",
}));

const CustomListItemText = styled(ListItemText)(({ theme }) => ({
	color: theme.palette.primary.main, // Apply primary color here too
}));

interface PageMenuData {
	iconName: string;
	label: string;
	link: string;
}

interface PageMenuProps {
	pageMenuData: PageMenuData[];
}

const PageMenu = ({ pageMenuData }: PageMenuProps) => {
	return (
		<>
			<List>
				{pageMenuData.map((item, i) => (
					<ListItem divider={true} key={i}>
						<MenuLink to={item.link}>
							<ListItemButton>
								<ListItemIcon>
									<Icon>{item.iconName}</Icon>
								</ListItemIcon>
								<CustomListItemText color="inherit" primary={item.label} />
							</ListItemButton>
						</MenuLink>
					</ListItem>
				))}
			</List>
		</>
	);
};

export default PageMenu;
