import { Button, Grid2 as Grid, Icon, IconButton, InputAdornment, TextField } from "@mui/material";
import MaterialItem from "~material/components/MaterialItem";
import { useContext, useEffect, useRef } from "react";

import { AuthContext } from "~/auth";
import SelectLocation from "~/components/select/SelectLocation";
import SelectReason from "~/components/select/SelectReason";
import SpacedGridContainer from "~/components/SpacedGridContainer";
import SpacedGridItem from "~/components/SpacedGridItem";
import { useAppDispatch, useAppSelector } from "~/hooks/reduxHooks";
import { useSnacks } from "~/providers/SnackProvider";
import {
	CountMaterialItemMutationVariables,
	CountReasonData,
	LocationType,
	MaterialItemCountType,
	MoveMaterialItemMutationVariables,
	useCountMaterialItemMutation,
	useMoveMaterialItemMutation,
} from "~/services/graphql";
import {
	closeMaterialItemEdit,
	updateMaterialItemCount,
	updateMaterialItemLocationId,
	updateMaterialItemReason,
	clearMaterialItemReason,
} from "~/store/material/materialItemSlice";

const MaterialItemEdit = () => {
	const countRef = useRef<HTMLInputElement>(null);
	const dispatch = useAppDispatch();
	const authContext = useContext(AuthContext);
	const [openSnack] = useSnacks();
	const materialItemLocationQuantity = useAppSelector((state) => state.material.materialItem.materialItemLocationQuantity);
	const materialItemLocationId = useAppSelector((state) => state.material.materialItem.locationId);
	const materialItemCount = useAppSelector((state) => state.material.materialItem.count);
	const materialItemReason = useAppSelector((state) => state.material.materialItem.reason);
	const siteLocationId = useAppSelector((state) => state.site.location?.id);

	useEffect(() => {
		setTimeout(() => {
			countRef.current?.focus();
		}, 100);
	}, [countRef.current]);

	const [countMaterialItemMutation] = useCountMaterialItemMutation();
	const [moveMaterialItemMutation] = useMoveMaterialItemMutation();
	const newMaterialItem = !materialItemLocationQuantity?.locationId ? true : false;

	const handleSubmit = async () => {
		//initial checks
		const errors = [];

		if (!siteLocationId) {
			errors.push("Site not found");
		}

		if (!authContext.company?.abbreviation) {
			errors.push("Tenant Id not found");
		}
		if (!materialItemLocationQuantity?.materialItemId) {
			errors.push("Material Item Id not found");
		}
		if (materialItemCount < (newMaterialItem ? 1 : 0)) {
			errors.push("Enter a count");
		}
		if (!authContext.currentUser?.uuid) {
			errors.push("User Id not found");
		}
		if (!materialItemReason?.code) {
			errors.push("Reason Code not found");
		}

		// are we counting or moving the material item?
		// if the location id is not null anis different from the current location id, we are moving the material item
		if (!newMaterialItem && materialItemLocationId !== materialItemLocationQuantity?.locationId) {
			// we are moving the material item

			if (!materialItemLocationId) {
				errors.push("New Location not found");
			}
			if (errors.length > 0) {
				openSnack(errors.join(", "), "warning");
				return;
			}

			const moveMaterialItemVariables: MoveMaterialItemMutationVariables = {
				input: {
					countedRecorded: new Date().toISOString(),
					fromLocationId: materialItemLocationQuantity?.locationId || "",
					materialItemId: materialItemLocationQuantity?.materialItemId || "",
					movedByUserId: authContext.currentUser?.uuid || "",
					quantityMoved: materialItemCount || 0,
					reasonCode: materialItemReason?.code,
					siteLocationId: siteLocationId || "",
					tenantId: authContext.company?.abbreviation || "NOTFOUND",
					toLocationId: materialItemLocationId || "",
				},
			};

			try {
				const result = await moveMaterialItemMutation(moveMaterialItemVariables);
				if (result.error || !result.data || result.data.moveMaterialItem?.errors) {
					openSnack("Error posting move", "error");
					return;
				}
			} catch (error) {
				console.error("Error posting move", error);
				openSnack("Error posting move", "error");
				return;
			}
		} else {
			// we are counting the material item
			if (errors.length > 0) {
				openSnack(errors.join(", "), "warning");
				return;
			}

			const countMaterialItemVariables: CountMaterialItemMutationVariables = {
				input: {
					countedByUserId: authContext.currentUser?.uuid || "",
					countedRecorded: new Date().toISOString(),
					countType: newMaterialItem ? ("INCREMENT" as MaterialItemCountType) : ("SET" as MaterialItemCountType),
					locationId: newMaterialItem ? materialItemLocationId || "" : materialItemLocationQuantity?.locationId || "",
					materialItemId: materialItemLocationQuantity?.materialItemId || "",
					quantityCounted: materialItemCount || 0,
					reasonCode: materialItemReason?.code,
					siteLocationId: siteLocationId || "",
					tenantId: authContext.company?.abbreviation || "NOTFOUND",
				},
			};
			try {
				const result = await countMaterialItemMutation(countMaterialItemVariables);
				if (result.error || !result.data || result.data.countMaterialItem?.errors) {
					openSnack("Error posting count", "error");
					return;
				}
			} catch (error) {
				console.error("Error posting count", error);
				openSnack("Error posting count", "error");
				return;
			}
		}

		dispatch(updateMaterialItemCount(0));
		dispatch(updateMaterialItemLocationId(null));
		dispatch(closeMaterialItemEdit());
	};

	const handleReasonChange = (val: CountReasonData | null) => {
		if (val) {
			dispatch(updateMaterialItemReason(val));
		} else {
			dispatch(clearMaterialItemReason());
		}
	};

	const handleCancel = () => {
		dispatch(updateMaterialItemCount(0));
		dispatch(updateMaterialItemLocationId(null));
		dispatch(closeMaterialItemEdit());
	};
	console.log("MaterialItemCount", materialItemCount);
	return (
		<SpacedGridContainer>
			<MaterialItem />
			<SpacedGridItem maxCols={1} smMargin>
				<SelectLocation
					currentLocationId={materialItemLocationQuantity?.locationId}
					handleInputChange={(val) => dispatch(updateMaterialItemLocationId(val))}
					locationTypes={[LocationType.Bin]}
					saveKey="materialItemLocation"
				/>
			</SpacedGridItem>

			<SpacedGridItem maxCols={1} smMargin>
				<TextField
					autoFocus
					fullWidth
					inputRef={countRef}
					label="Count"
					onChange={(e) => dispatch(updateMaterialItemCount(parseInt(e.target.value || "-1")))}
					slotProps={{
						input: {
							endAdornment:
								materialItemCount > -1 ? (
									<InputAdornment position="end">
										<IconButton
											onClick={() => {
												dispatch(updateMaterialItemCount(-1));
												countRef.current?.focus();
											}}
										>
											<Icon>clear</Icon>
										</IconButton>
									</InputAdornment>
								) : null,
						},
					}}
					value={newMaterialItem ? (materialItemCount < 1 ? "" : String(materialItemCount)) : materialItemCount < 0 ? "" : String(materialItemCount)}
				/>
			</SpacedGridItem>
			<SpacedGridItem maxCols={1} smMargin>
				<SelectReason countReason={materialItemReason} handleInputChange={handleReasonChange} />
			</SpacedGridItem>
			<SpacedGridItem maxCols={1} smMargin>
				<Grid alignItems="stretch" container direction="row" spacing={1}>
					<Grid size="grow">
						<Button fullWidth onClick={() => handleSubmit()} variant="contained">
							Submit
						</Button>
					</Grid>
					<Grid>
						<Button color="inherit" onClick={handleCancel} variant="contained">
							Cancel
						</Button>
					</Grid>
				</Grid>
			</SpacedGridItem>
		</SpacedGridContainer>
	);
};

export default MaterialItemEdit;
