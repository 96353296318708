import { Alert, Button, Grid2 as Grid, Icon, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import {
	DataGrid,
	GridColumnVisibilityModel,
	GridLogicOperator,
	GridToolbar,
	GridToolbarProps,
	GridValidRowModel,
	ToolbarPropsOverrides,
} from "@mui/x-data-grid";
import { GridApiCommunity } from "@mui/x-data-grid/internals";
import { Fragment, useEffect, useState } from "react";

import { useAppSelector, useAppDispatch } from "~/hooks/reduxHooks";
import { updateSettings } from "~/store/settingsSlice";

import HeaderCard from "./HeaderCard";
import SelectCard from "./select/SelectCard";
import SelectNative from "./select/SelectNative";
import SpacedGridContainer from "./SpacedGridContainer";
import SpacedGridItem from "./SpacedGridItem";

interface DataCardGridOptions {
	force?: "table" | "card";
	hidePagination?: boolean;
	hideToolbar?: boolean;
	showAddNew?: boolean;
}

interface DataCardGridProps {
	cardColumnsRender?: { field: string; render: (row: unknown) => React.ReactNode }[];
	error?: boolean;
	force?: "table" | "card";
	loading?: boolean;
	onSelect: (row: unknown) => void;
	options?: DataCardGridOptions;
	title?: string;
	toolbar?: Partial<GridToolbarProps & ToolbarPropsOverrides>;
}

const DataCardGrid: React.FC<DataCardGridProps & React.ComponentProps<typeof DataGrid>> = ({
	cardColumnsRender,
	error,
	force,
	loading,
	onSelect,
	options,
	...otherProps
}) => {
	const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>(otherProps.columnVisibilityModel || {});
	const [cardData, setCardData] = useState<readonly GridValidRowModel[]>(otherProps.rows || []);

	const viewType = useAppSelector((state) => state.layout.viewType);

	const cardPage = Number(otherProps.paginationModel?.page);
	const cardPageSize = Number(otherProps.paginationModel?.pageSize);
	const cardRowCount = Number(otherProps.rowCount);
	const cardFilter = otherProps.filterModel?.quickFilterValues || [];

	// Pagination Logic
	const fromRecord = (cardPage + 1) * cardPageSize - cardPageSize + 1;
	const toRecord = (cardPage + 1) * cardPageSize > cardRowCount ? cardRowCount : (cardPage + 1) * cardPageSize;

	const itemsPerPageOptions = [10, 25, 50, 100];

	const pageNumbers = [];
	for (let i = 1; i <= Math.ceil(cardRowCount / cardPageSize); i++) {
		pageNumbers.push(i);
	}

	const dispatch = useAppDispatch();

	// Save Pagination Page Size to Redux
	useEffect(() => {
		if (!options?.hidePagination) {
			dispatch(updateSettings({ paginationPageSize: cardPageSize }));
		}
	}, [cardPageSize]);

	useEffect(() => {
		setCardData(otherProps.rows || []);
	}, [otherProps.rows]);

	const addNewButton = options?.showAddNew ? (
		<SpacedGridItem>
			<Button color="secondary" fullWidth onClick={() => onSelect({})} sx={{ marginBottom: 2 }} variant="contained">
				Add New
			</Button>
		</SpacedGridItem>
	) : null;

	const statusComponent = () => {
		if (error) {
			return (
				<SpacedGridItem maxCols={1}>
					<Alert severity="error">Error: see error log</Alert>
				</SpacedGridItem>
			);
		}
	};
	if (force === "table" || (force !== "card" && viewType === "table")) {
		return (
			<>
				{statusComponent()}
				{addNewButton}
				<div style={{ display: "flex", flexDirection: "column", minHeight: "200px", width: "100%" }}>
					<DataGrid
						{...{ columns: otherProps.columns, rows: otherProps.rows }}
						columnVisibilityModel={columnVisibilityModel}
						filterMode="server"
						hideFooter={options?.hidePagination === true ? true : false}
						initialState={{
							filter: {
								filterModel: {
									items: otherProps.filterModel?.items || [],
									quickFilterValues: otherProps.filterModel?.quickFilterValues || [],
								},
							},
						}}
						loading={loading}
						onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
						onFilterModelChange={otherProps.onFilterModelChange}
						onPaginationModelChange={otherProps.onPaginationModelChange}
						onRowClick={(params) => onSelect(params.row)}
						onSortModelChange={otherProps.onSortModelChange}
						pageSizeOptions={itemsPerPageOptions}
						paginationMode="server"
						paginationModel={otherProps.paginationModel}
						rowCount={otherProps.rowCount}
						slotProps={{
							loadingOverlay: {
								noRowsVariant: "skeleton",
								variant: "skeleton",
							},
							toolbar: {
								showQuickFilter: true,
							},
						}}
						slots={{
							toolbar: options?.hideToolbar === true ? null : GridToolbar,
						}}
						sortingMode="server"
					/>
				</div>
			</>
		);
	}

	return (
		<SpacedGridContainer alignItems="stretch">
			{otherProps.filterModel?.items?.length ? (
				<SpacedGridItem maxCols={1} smMargin>
					<Alert severity="info">Filtering by: {otherProps.filterModel.items.map((item) => item.field + " : " + item.value).join(", ")}</Alert>
				</SpacedGridItem>
			) : null}
			{addNewButton}
			{options?.hideToolbar === true ? null : (
				<SpacedGridItem maxCols={1} smMargin style={{ marginBottom: "10px", textAlign: "right" }}>
					<TextField
						autoFocus
						fullWidth
						onChange={(e) => {
							otherProps.onFilterModelChange &&
								otherProps.onFilterModelChange(
									{
										items: [],
										logicOperator: GridLogicOperator.And,
										quickFilterLogicOperator: GridLogicOperator.And,
										quickFilterValues: e.target.value.split(" "),
									},
									{ api: {} as GridApiCommunity }
								);
						}}
						placeholder="Search..."
						slotProps={{
							input: {
								endAdornment: cardFilter.length ? (
									<InputAdornment position="end">
										<IconButton
											onClick={() => {
												otherProps.onFilterModelChange &&
													otherProps.onFilterModelChange(
														{
															items: [],
															logicOperator: GridLogicOperator.And,
															quickFilterLogicOperator: GridLogicOperator.And,
															quickFilterValues: [],
														},
														{ api: {} as GridApiCommunity }
													);
											}}
										>
											<Icon>clear</Icon>
										</IconButton>
									</InputAdornment>
								) : null,
								startAdornment: (
									<InputAdornment position="start">
										<Icon>search</Icon>
									</InputAdornment>
								),
							},
						}}
						sx={{ borderColor: "transparent" }}
						value={cardFilter.join(" ")}
						variant="outlined"
					/>
				</SpacedGridItem>
			)}
			{cardData && cardData.length ? (
				<>
					{cardData.map((row, i) => {
						if (!row) return null;
						return (
							<SpacedGridItem key={i} smMargin>
								<SelectCard
									onClick={() => {
										onSelect(row);
									}}
								>
									{otherProps.columns.map((col, j) => {
										if (otherProps.columnVisibilityModel && otherProps.columnVisibilityModel[col.field] === false) return null;
										return (
											<Fragment key={j}>
												<Grid size={4} style={{ textAlign: "right" }}>
													<Typography color="textSecondary" component="span" variant="inherit">
														{col.headerName} :
													</Typography>
												</Grid>
												<Grid size={8}>{(cardColumnsRender && cardColumnsRender.find((a) => a.field == col.field)?.render(row)) || row[col.field]}</Grid>
											</Fragment>
										);
									})}
								</SelectCard>
							</SpacedGridItem>
						);
					})}
					{options?.hideToolbar === true ? null : (
						<SpacedGridItem maxCols={1} smMargin={true}>
							<HeaderCard alignItems="stretch" container direction="row">
								<Grid textAlign="center">
									<div style={{ lineHeight: "2.2em" }}>
										<Typography component="span" style={{ paddingRight: ".3rem" }} variant="caption">
											per page
										</Typography>
										<SelectNative
											fullWidth={false}
											onChange={(e) => {
												otherProps.onPaginationModelChange &&
													otherProps.onPaginationModelChange(
														{ page: 0, pageSize: Number(e.target.value) },
														{ api: {} as GridApiCommunity, reason: "buttonClick" }
													);
											}}
											options={itemsPerPageOptions.map((pn) => ({ label: String(pn), value: pn }))}
											value={cardPageSize}
										/>
									</div>
								</Grid>
								<Grid size="grow" textAlign="center">
									<div style={{ lineHeight: "2.2em" }}>
										<Typography component="span" style={{ paddingTop: ".3rem" }} variant="body1">
											{fromRecord}-{toRecord} of {cardRowCount}
										</Typography>
									</div>
								</Grid>
								<Grid>
									<IconButton
										color="inherit"
										disabled={cardPage > 0 ? false : true}
										onClick={() =>
											otherProps.onPaginationModelChange &&
											otherProps.onPaginationModelChange(
												{ page: cardPage - 1 || 0, pageSize: cardPageSize || 10 },
												{ api: {} as GridApiCommunity, reason: "buttonClick" }
											)
										}
										sx={{ marginRight: 1 }}
									>
										<Icon>chevron_left</Icon>
									</IconButton>

									<IconButton
										color="inherit"
										disabled={cardPage + 1 < pageNumbers.length ? false : true}
										onClick={() =>
											otherProps.onPaginationModelChange &&
											otherProps.onPaginationModelChange(
												{ page: cardPage + 1 || 0, pageSize: cardPageSize || 10 },
												{ api: {} as GridApiCommunity, reason: "buttonClick" }
											)
										}
										sx={{ marginRight: 1 }}
									>
										<Icon>chevron_right</Icon>
									</IconButton>
								</Grid>
							</HeaderCard>
						</SpacedGridItem>
					)}
				</>
			) : (
				<SpacedGridItem maxCols={1}>
					<Alert severity="info">No results found</Alert>
				</SpacedGridItem>
			)}
		</SpacedGridContainer>
	);
};

export default DataCardGrid;
