import CountClipboard from "~count/CountClipboard";
import MaterialItems from "~material/MaterialItems";
import MaterialMenu from "~material/MaterialMenu";
import VendorPurchaseOrders from "~material/VendorPurchaseOrders";
import { Route, Routes } from "react-router-dom";
const Material = () => {
	return (
		<>
			<Routes>
				<Route element={<MaterialMenu />} path="" />
				<Route element={<CountClipboard />} path="clipboard" />
				<Route element={<MaterialItems />} path="items" />
				<Route element={<VendorPurchaseOrders />} path="orders" />
			</Routes>
		</>
	);
};

export default Material;
