import { WorkOrderStatus } from "~/services/graphql";

export const getWorkOrderStatus = (status: WorkOrderStatus) => {
	switch (status) {
		case WorkOrderStatus.New:
			return "New";
		case WorkOrderStatus.InProgress:
			return "In Progress";
		case WorkOrderStatus.Closed:
			return "Produced";
		default:
			return "Unknown";
	}
};

export const getWorkOrderChipColor = (status: WorkOrderStatus) => {
	switch (status) {
		case WorkOrderStatus.New:
			return "info";
		case WorkOrderStatus.InProgress:
			return "warning";
		case WorkOrderStatus.Closed:
			return "success";
		default:
			return "default";
	}
};
