import { Button, Grid2 as Grid, InputAdornment, TextField, Tooltip, Typography } from "@mui/material";
import { useState } from "react";

import { useAppDispatch, useAppSelector } from "~/hooks/reduxHooks";
import { MaterialItemDocument, VendorPurchaseOrderItem } from "~/services/graphql";
import { setVendorOrderReceiveQuantities } from "~/store/material/vendorOrderSlice";

interface OrderReceiveLineProps {
	materialItem: MaterialItemDocument | null;
	orderLine: VendorPurchaseOrderItem;
}

export function OrderReceiveLine({ materialItem, orderLine }: OrderReceiveLineProps) {
	const dispatch = useAppDispatch();
	const [ttOpen, setTtOpen] = useState(false);

	const vendorOrderReceiveQuantities = useAppSelector((state) => state.material.vendorOrder.vendorOrderReceiveQuantities);

	const receivedQuantity = vendorOrderReceiveQuantities.find((q) => q.id === orderLine.id)?.receivedQuantity || 0;

	return (
		<Grid container spacing={1}>
			<Grid size={12}>
				<Grid container spacing={1}>
					<Grid size="grow">{`${orderLine.itemName}`} </Grid>
					<Grid> ERP ID: {`${materialItem?.erpId}`}</Grid>
				</Grid>
			</Grid>
			<Grid size={6}>
				<Typography color="textSecondary" variant="inherit">
					Order Quantity{" "}
				</Typography>
			</Grid>
			<Grid size={6}>
				<Typography color="textSecondary" variant="inherit">
					Received Quantity{" "}
				</Typography>
			</Grid>
			<Grid size={6} style={{ textAlign: "start" }}>
				<Button
					color="inherit"
					onClick={() => dispatch(setVendorOrderReceiveQuantities({ id: orderLine.id, receivedQuantity: orderLine.quantity }))}
					size="small"
					variant="contained"
				>
					{`${orderLine.quantity}`} &gt;
				</Button>
			</Grid>
			<Grid size={6} style={{ textAlign: "end" }}>
				<TextField
					error={receivedQuantity < 0 || orderLine.quantity < receivedQuantity}
					fullWidth={true}
					helperText={receivedQuantity < 0 || orderLine.quantity < receivedQuantity ? "Quantity greater than order quantity" : null}
					onChange={(e) => dispatch(setVendorOrderReceiveQuantities({ id: orderLine.id, receivedQuantity: Number(e.target.value) }))}
					placeholder="0"
					slotProps={{
						input: {
							endAdornment: (
								<Tooltip
									PopperProps={{
										disablePortal: true,
									}}
									leaveTouchDelay={3000}
									onClose={() => {
										setTtOpen(false);
									}}
									open={ttOpen}
									title={"UOM not found"}
								>
									<InputAdornment
										onClick={() => {
											setTtOpen(true);
										}}
										position="end"
									>
										{null}
									</InputAdornment>
								</Tooltip>
							),
						},
					}}
					type="number"
					value={receivedQuantity || ""}
					variant="standard"
				/>
			</Grid>
		</Grid>
	);
}

export default OrderReceiveLine;
