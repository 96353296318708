import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state
interface SettingsState {
	numberOfProductionLines: number;
	paginationPageSize: number;
}

const initialState: SettingsState = {
	numberOfProductionLines: 1,
	paginationPageSize: 10,
};

export const settingsSlice = createSlice({
	initialState,
	name: "settings",
	reducers: {
		updateSettings: (state, action: PayloadAction<Partial<SettingsState>>) => {
			if (!action.payload) {
				return initialState;
			} else {
				return { ...state, ...action.payload };
			}
		},
	},
});

export const { updateSettings } = settingsSlice.actions;

export default settingsSlice.reducer;
