import { Navigate } from "react-router-dom";

import PageMenu from "~/components/PageMenu";

const pageMenuData = [
	{
		iconName: "category",
		label: "Items",
		link: "/material/items",
	},
];

const defaultPage = "/material/items";

const MaterialMenu = () => {
	const lastPage = localStorage.getItem("lastPage");
	//if lastpage starts with count use that page
	if (lastPage && lastPage.startsWith("material")) {
		return <Navigate replace={true} to={`/${lastPage}`} />;
	} else if (defaultPage) {
		return <Navigate replace={true} to={defaultPage} />;
	}

	return <PageMenu pageMenuData={pageMenuData} />;
};

export default MaterialMenu;
