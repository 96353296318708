const Config: AppConfig = {
	appName: import.meta.env["VITE_APPLICATION_NAME"]!,
	authUrl: import.meta.env["VITE_AUTH_URL"]!,
	branch: import.meta.env["VITE_BUILD_BRANCH"]!,
	buildVersion: import.meta.env["VITE_BUILD_VERSION"]!,
	clientId: import.meta.env["VITE_CLIENT_ID"]!,
	decimalPrecision: 5,
	endpoint: import.meta.env["VITE_GRAPHQL_ENDPOINT"]!,
	environment: import.meta.env["VITE_BUILD_ENV"]!,
	idbVersion: 1,
	navDrawerCoverWidth: 900,
	navDrawerWidth: 270,
	releaseVersion: import.meta.env["VITE_RELEASE_VERSION"]!,
	sentryDSN: import.meta.env["VITE_SENTRY_DSN"]!,
	sessionManagerUrl: import.meta.env["VITE_SESSION_MANAGER_URI"]!,
	sfApiUrl: import.meta.env["VITE_SF_API_URL"]!,
	tenantId: import.meta.env["VITE_TENANT_ID"]!,
	tokenDuration: parseInt(import.meta.env["VITE_TOKEN_DURATION_SECONDS"]!) || 3600,
	usePKCE: import.meta.env["VITE_USE_PKCE"] === "true",
};

export default Config;
