import { Theme } from "@mui/material";
import { grey, green } from "@mui/material/colors";
import { createTheme, lighten, darken } from "@mui/material/styles";

import { themeOverrides, themeOverridesDark } from "~/themes/themeOverrides";

let fontSize = 14;

declare module "@mui/material/styles" {
	interface Palette {
		headerCard: Palette["primary"];
		selectCard: Palette["primary"];
	}

	interface PaletteOptions {
		headerCard: PaletteOptions["primary"];
		selectCard: PaletteOptions["primary"];
	}
}

export function themeCreator(theme: string, type: string, defaultFontSize?: string | null): Theme {
	fontSize = Number(defaultFontSize) || fontSize;

	const headerCard = {
		contrastText: "#333333",
		dark: grey[300],
		light: grey[50],
		main: grey[200],
	};

	const headerCardDark = {
		contrastText: "#eeeeee",
		dark: grey[900],
		light: grey[700],
		main: grey[800],
	};

	//bgcolor = "#222831";
	//bgcolordark = "#e1f5fe";

	const selectCard = {
		dark: green[200],
		light: lighten(green[50], 0.9),
		main: lighten(green[100], 0.8),
	};

	const selectCardDark = {
		dark: green[900],
		light: green[700],
		main: darken(green[900], 0.8),
	};

	const mainTheme = createTheme({
		components: themeOverrides,
		palette: {
			background: {
				default: "#f5f5f5",
			},
			headerCard: headerCard,
			primary: {
				main: "#335936" /*"#69936C"*/,
			},
			secondary: {
				main: "#8C5522",
			},
			selectCard: selectCard,
			text: {
				primary: "#555555",
				secondary: "#777777",
			},
		},
		typography: {
			fontSize: fontSize,
		},
	});

	const mainThemeDark = createTheme({
		components: themeOverridesDark,
		palette: {
			background: {
				default: "#222222",
			},
			headerCard: headerCardDark,
			mode: "dark",
			primary: {
				main: "#538E58",
			},
			secondary: {
				main: "#999999",
			},
			selectCard: selectCardDark,
			text: {
				primary: "#dddddd",
				secondary: "#aaaaaa",
			},
		},
		typography: {
			fontSize: fontSize,
		},
	});

	const themeMap: { [key: string]: Theme } = {
		main: mainTheme,
	};

	const themeMapDark: { [key: string]: Theme } = {
		main: mainThemeDark,
	};

	if (type === "dark") {
		if (themeMapDark[theme]) {
			return { ...themeMapDark[theme] };
		} else {
			return { ...themeMapDark["main"] };
		}
	}
	if (themeMap[theme]) {
		return { ...themeMap[theme] };
	} else {
		return { ...themeMap["main"] };
	}
}
