//display the page title and description (if available), shows at top of page

import { Box, Typography } from "@mui/material";
import { useMediaQuery } from "@mui/material";

import Config from "~/config";
import { useAppSelector } from "~/hooks/reduxHooks";

const PageHeader = () => {
	const pageTitle = useAppSelector((state) => state.layout.pageTitle);
	const pageDescription = useAppSelector((state) => state.layout.pageDescription);

	const isWideScreen = useMediaQuery("(min-width:" + Config.navDrawerCoverWidth + "px)");

	if (!isWideScreen) return null;

	return (
		<Box mb={2}>
			{pageTitle && (
				<Typography color="primary.light" variant="h5">
					{pageTitle}
				</Typography>
			)}
			{pageDescription && (
				<Typography color="text.secondary" m={1} variant="body1">
					{pageDescription}
				</Typography>
			)}
		</Box>
	);
};

export default PageHeader;
