import { Button, Grid2 as Grid, TextField } from "@mui/material";
import { useEffect } from "react";

import SelectLocation from "~/components/select/SelectLocation";
import SpacedGridContainer from "~/components/SpacedGridContainer";
import SpacedGridItem from "~/components/SpacedGridItem";
import { useAppDispatch, useAppSelector } from "~/hooks/reduxHooks";
import { LocationType } from "~/services/graphql";
import { closeAddSetDown, updateSetDownCount, updateSetDownLocationId } from "~/store/count/setDownSlice";

interface SetDownAddProps {
	handleAddSetDown: (count: number, locationId: string) => void;
}

const SetDownAdd = ({ handleAddSetDown }: SetDownAddProps) => {
	const dispatch = useAppDispatch();

	const count = useAppSelector((state) => state.count.setDown.count);

	const locationId = useAppSelector((state) => state.count.setDown.locationId);

	const handleLocationChange = (val: string | null) => {
		dispatch(updateSetDownLocationId(val));
	};

	const handleCancel = () => {
		dispatch(updateSetDownCount(0));
		dispatch(updateSetDownLocationId(null));
		dispatch(closeAddSetDown());
	};

	useEffect(() => {
		console.log("count", count);
	}, [count]);
	return (
		<SpacedGridContainer>
			<SpacedGridItem maxCols={1} smMargin>
				<TextField fullWidth label="Count" onChange={(e) => dispatch(updateSetDownCount(parseInt(e.target.value)))} value={count || ""} />
			</SpacedGridItem>
			<SpacedGridItem maxCols={1} smMargin>
				<SelectLocation handleInputChange={handleLocationChange} locationTypes={[LocationType.Bin]} />
			</SpacedGridItem>
			<SpacedGridItem maxCols={1} smMargin>
				<Grid alignItems="stretch" container direction="row" spacing={1}>
					<Grid size="grow">
						<Button fullWidth onClick={() => count && locationId && handleAddSetDown(count, locationId)} variant="contained">
							Submit
						</Button>
					</Grid>
					<Grid>
						<Button color="inherit" onClick={handleCancel} variant="contained">
							Cancel
						</Button>
					</Grid>
				</Grid>
			</SpacedGridItem>
		</SpacedGridContainer>
	);
};

export default SetDownAdd;
