import { Navigate } from "react-router-dom";

import PageMenu from "~/components/PageMenu";

const pageMenuData = [
	{
		iconName: "monitor",
		label: "Production Lines",
		link: "/count/lines",
	},
	/*	{
		link: "/count/counting",
		label: "Production Counting",
		icon: <PlusOneOutlined />,
	},*/
	{
		iconName: "warehouse_outlined",
		label: "Set Down",
		link: "/count/setdown",
	},
];

const defaultPage = "/count/lines";

const CountMenu = () => {
	const lastPage = localStorage.getItem("lastPage");
	//if lastpage starts with count use that page
	if (lastPage && lastPage.startsWith("count")) {
		return <Navigate replace={true} to={`/${lastPage}`} />;
	} else if (defaultPage) {
		return <Navigate replace={true} to={defaultPage} />;
	}

	return <PageMenu pageMenuData={pageMenuData} />;
};

export default CountMenu;
