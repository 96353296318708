import { Box, BoxProps, darken, lighten, Theme } from "@mui/material";

const Footer: React.FC<BoxProps & React.ComponentProps<typeof Box>> = ({ children, ...props }) => {
	const getBackgroundColor = (theme: Theme) => {
		return theme.palette.mode === "dark" ? lighten(theme.palette.background.default, 0.05) : darken(theme.palette.background.default, 0.05);
	};

	const getColor = (theme: Theme) => {
		return theme.palette.mode === "dark" ? theme.palette.text.secondary : theme.palette.text.secondary;
	};

	return (
		<Box p={1} sx={{ backgroundColor: (theme) => getBackgroundColor(theme), color: (theme) => getColor(theme), height: "48px", marginTop: "auto" }} {...props}>
			{children}
		</Box>
	);
};

export default Footer;
