import { CssBaseline, StyledEngineProvider, ThemeProvider } from "@mui/material";
import { createContext, useState } from "react";

import { themeCreator } from "~/themes/pageThemes";

export const ThemeContext = createContext({
	setTheme: (themeName: string) => {
		String(themeName);
	},
	setThemeSize: (size: string) => {
		Number(size);
	},
	setThemeType: (type: string) => {
		String(type);
	},
	themeName: "mainTheme",
	themeSize: "14",
	themeType: "light",
});

interface AppThemeProps {
	children: JSX.Element;
}

const AppThemeProvider = (props: AppThemeProps) => {
	// State to hold the selected theme name
	const { children } = props;

	const userpref = localStorage.getItem("themeType") === "dark" ? "dark" : "light";
	const userprefSize = localStorage.getItem("themeSize") || "14";
	const [themeName, setThemeName] = useState("main");
	const [themeType, setThemeTypePref] = useState(userpref);
	const [themeSize, setThemeSizePref] = useState(userprefSize);

	const theme = themeCreator(themeName, themeType, themeSize);

	function setThemeType(type: string) {
		localStorage.setItem("themeType", type);
		setThemeTypePref(type);
	}

	function setThemeSize(size: string) {
		localStorage.setItem("themeSize", size);
		setThemeSizePref(size);
	}

	const contextValue = {
		setTheme: setThemeName,
		setThemeSize: setThemeSize,
		setThemeType: setThemeType,
		themeName: themeName,
		themeSize: themeSize,
		themeType: themeType,
	};

	return (
		<StyledEngineProvider injectFirst>
			<ThemeContext.Provider value={contextValue}>
				<ThemeProvider theme={theme}>
					<CssBaseline />
					{children}
				</ThemeProvider>
			</ThemeContext.Provider>
		</StyledEngineProvider>
	);
};

export default AppThemeProvider;
