import { GridFilterModel, GridPaginationModel, GridSortModel } from "@mui/x-data-grid";
import MaterialItemEdit from "~material/components/MaterialItemEdit";
import { useContext, useEffect, useState } from "react";

import { AuthContext } from "~/auth/AuthProvider";
import DataCardGrid from "~/components/DataCardGrid2";
import ModalDialog from "~/components/ModalDialog";
import SpacedGridContainer from "~/components/SpacedGridContainer";
import SpacedGridItem from "~/components/SpacedGridItem";
import { useAppDispatch, useAppSelector } from "~/hooks/reduxHooks";
import {
	MaterialItemDocument,
	MaterialItemsQueryVariables,
	useMaterialItemsQuery,
	useMaterialItemSiteLocationQuantitiesQuery,
	MaterialItemSiteLocationQuantitiesQueryVariables,
	SortEnumType,
	MaterialItemDocumentFilterInput,
} from "~/services/graphql";
import {
	closeMaterialItemLocationQuantities,
	openMaterialItemLocationQuantities,
	resetMaterialItemLocationQuantity,
	setMaterialItem,
} from "~/store/material/materialItemSlice";

import MaterialItemLocationQuantities from "./components/MaterialItemLocationQuantities";

const columns = [
	{ field: "id", flex: 1, headerName: "id" },
	{ field: "description", flex: 1, headerName: "Description" },
	{ field: "quantity", filterable: false, flex: 1, headerName: "Quantity", sortable: false },
	{ field: "erpId", flex: 1, headerName: "ERP ID" },
	{ field: "name", flex: 1, headerName: "Name" },
	{ field: "status", flex: 1, headerName: "Status" },
	{ field: "tenantId", flex: 1, headerName: "Tenant ID" },
	{ field: "created", flex: 1, headerName: "Created" },
	{ field: "updated", flex: 1, headerName: "Updated" },
];

const columnVisibilityModel = {
	created: false,
	description: true,
	erpId: true,
	id: false,
	name: true,
	quantity: true,
	status: true,
	tenantId: false,
	updated: false,
};

const columnFilterFields = ["description", "erpId", "name"];

type MergedMaterialItem = MaterialItemDocument & {
	quantity: number;
};

const MaterialItems = () => {
	const authContext = useContext(AuthContext);

	const dispatch = useAppDispatch();
	const editingMaterialItem = useAppSelector((state) => state.material.materialItem.editingMaterialItem);
	const viewingMaterialItemLocationQuantities = useAppSelector((state) => state.material.materialItem.viewingMaterialItemLocationQuantities);
	const siteLocationId = useAppSelector((state) => state.site.location?.id);
	const materialItemLocationQuantity = useAppSelector((state) => state.material.materialItem.materialItemLocationQuantity);

	const handleSelectMaterialItem = (row: unknown) => {
		const materialItem = row as MaterialItemDocument;
		dispatch(setMaterialItem(materialItem));
		dispatch(openMaterialItemLocationQuantities());
		return;
	};

	useEffect(() => {
		localStorage.setItem("lastPage", "material/items");
	}, []);

	useEffect(() => {
		if (!editingMaterialItem) {
			dispatch(resetMaterialItemLocationQuantity());
		}
	}, [editingMaterialItem]);

	const [currentPage, setCurrentPage] = useState(0);
	const [startCursor, setStartCursor] = useState<string | null | undefined>();
	const [endCursor, setEndCursor] = useState<string | null | undefined>();
	const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "name", sort: "asc" }]);
	const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });
	const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({ page: 0, pageSize: 10 });
	const [mergedMaterialItems, setMergedMaterialItems] = useState<MergedMaterialItem[]>([]);
	const [rowCount, setRowCount] = useState(0);

	useEffect(() => {
		console.log("filterModel", filterModel);
	}, [filterModel]);

	const updatePaginationModel = (paginationModel: GridPaginationModel) => {
		if (paginationModel.page === 0) {
			setStartCursor(undefined);
			setEndCursor(undefined);
		} else if (paginationModel.page < currentPage) {
			setStartCursor(materialItems?.materialItems?.pageInfo.startCursor || undefined);
			setEndCursor(undefined);
		} else if (paginationModel.page > currentPage) {
			setStartCursor(undefined);
			setEndCursor(materialItems?.materialItems?.pageInfo.endCursor || undefined);
		}

		setCurrentPage(paginationModel.page);

		setPaginationModel(paginationModel);
	};

	const grapqlWhere = (): Maybe<MaterialItemDocumentFilterInput> => {
		return {
			and:
				filterModel.items.map((filter) => ({
					[filter.field]: { contains: filter.value },
				})) || [],
			or: filterModel.quickFilterValues?.length
				? columnFilterFields.map((field) => ({
						[field]: { contains: (filterModel.quickFilterValues || []).join(" ") },
						// eslint-disable-next-line no-mixed-spaces-and-tabs
				  }))
				: [],
		};
	};

	const materialItemsVariables: MaterialItemsQueryVariables = {
		after: endCursor,
		before: startCursor,
		first: !startCursor ? paginationModel.pageSize : null, // Adjust number of items fetched as needed
		last: startCursor ? paginationModel.pageSize : null, // Adjust number of items fetched as needed
		order: sortModel.length ? { [sortModel[0].field]: sortModel[0].sort === "asc" ? SortEnumType.Asc : SortEnumType.Desc } : { name: SortEnumType.Asc },
		tenantId: authContext.company?.abbreviation || "NOTFOUND",
		where: grapqlWhere(),
	};

	const {
		data: materialItems,
		error: materialItemsError,
		isLoading: materialItemsLoading,
	} = useMaterialItemsQuery(materialItemsVariables, { pollingInterval: 30000 });

	const materialItemQuantitiesVariables: MaterialItemSiteLocationQuantitiesQueryVariables = {
		first: 1000,
		tenantId: authContext.company?.abbreviation || "NOTFOUND",
		where: {
			materialItemId: { in: (materialItems?.materialItems?.nodes || []).map((item) => item.id) },
			siteLocationId: { eq: siteLocationId || "NOTFOUND" },
		},
	};

	const {
		data: materialItemQuantities,
		error: materialItemQuantitiesError,
		isLoading: materialItemQuantitiesLoading,
	} = useMaterialItemSiteLocationQuantitiesQuery(
		materialItemQuantitiesVariables,
		{ skip: Number(materialItems?.materialItems?.nodes?.length) === 0 } // Skip the query if there are no material items
	);

	useEffect(() => {
		const mmi = (materialItems?.materialItems?.nodes || []).map((item) => {
			const quantity =
				(materialItemQuantities?.materialItemSiteLocationQuantities?.nodes || []).find((itemQuantity) => itemQuantity.materialItemId === item.id)?.quantity ||
				0;
			return {
				...item,
				quantity: Number(quantity),
			};
		});
		setMergedMaterialItems(mmi);
		setRowCount(materialItems?.materialItems?.totalCount || 0);
	}, [materialItems, materialItemQuantities]);

	useEffect(() => {
		if (rowCount === 1) {
			handleSelectMaterialItem(mergedMaterialItems[0]);
		}
	}, [mergedMaterialItems, rowCount]);

	return (
		<>
			<SpacedGridContainer>
				<SpacedGridItem maxCols={1}>
					<DataCardGrid
						columnVisibilityModel={columnVisibilityModel}
						columns={columns}
						error={materialItemsError || materialItemQuantitiesError ? true : false}
						filterModel={filterModel}
						loading={materialItemsLoading || materialItemQuantitiesLoading}
						onFilterModelChange={setFilterModel}
						onPaginationModelChange={updatePaginationModel}
						onSelect={handleSelectMaterialItem}
						onSortModelChange={setSortModel}
						paginationModel={paginationModel}
						rowCount={rowCount}
						rows={mergedMaterialItems}
						sortModel={sortModel}
						title="Material Items"
					/>
				</SpacedGridItem>
			</SpacedGridContainer>
			<ModalDialog
				close={() => {
					dispatch(closeMaterialItemLocationQuantities());
					return;
				}}
				open={viewingMaterialItemLocationQuantities}
				showCloseButton={true}
				title="Material Item Location Quantities"
			>
				<MaterialItemLocationQuantities />
			</ModalDialog>
			<ModalDialog
				close={() => {
					return;
				}}
				open={editingMaterialItem}
				title={materialItemLocationQuantity?.id ? "Edit Material Location Count" : "Add New Material Location Count"}
			>
				<MaterialItemEdit></MaterialItemEdit>
			</ModalDialog>
		</>
	);
};

export default MaterialItems;
