import { Grid2 as Grid, Grid2Props as GridProps } from "@mui/material";

const SpacedGridContainer: React.FC<GridProps & React.ComponentProps<typeof Grid>> = ({ children, ...props }) => {
	return (
		<Grid alignItems="baseline" columnSpacing={{ md: 2, xs: 0 }} container direction="row" mb={1} rowSpacing={{ md: 2, xs: 0 }} spacing={0} {...props}>
			{children}
		</Grid>
	);
};

export default SpacedGridContainer;
