//material-ui
import { Paper } from "@mui/material";

import SpacedGridContainer from "~/components/SpacedGridContainer";

const ItemCard: React.FC<React.ComponentProps<typeof SpacedGridContainer>> = ({ children, ...props }) => {
	return (
		<SpacedGridContainer
			alignItems="stretch"
			component={Paper}
			direction="row"
			padding={1}
			sx={{
				backgroundColor: (theme) => theme.palette.selectCard.main,
				height: "100%",
			}}
			{...props}
		>
			{children}
		</SpacedGridContainer>
	);
};

export default ItemCard;
