import { v4 as uuidv4 } from "uuid";
const wsId = (): string => {
	try {
		return uuidv4().replace(/-/g, "");
	} catch (e) {
		return "";
	}
};

export default wsId;
