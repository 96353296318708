import { GridSortModel, GridFilterModel, GridPaginationModel } from "@mui/x-data-grid";
import { useContext, useEffect, useState } from "react";

import { AuthContext } from "~/auth/AuthProvider";
import DataCardGrid from "~/components/DataCardGrid2";
import ModalDialog from "~/components/ModalDialog";
import SpacedGridContainer from "~/components/SpacedGridContainer";
import SpacedGridItem from "~/components/SpacedGridItem";
import { stringFormatDate } from "~/helpers/timestampFormat";
import { useAppDispatch, useAppSelector } from "~/hooks/reduxHooks";
import {
	SortEnumType,
	VendorPurchaseOrderDocument,
	VendorPurchaseOrderDocumentFilterInput,
	VendorPurchaseOrderItem,
	VendorPurchaseOrdersQueryVariables,
	useVendorPurchaseOrdersQuery,
} from "~/services/graphql";
import { openVendorOrderEdit } from "~/store/material/vendorOrderSlice";
import { setVendorPurchaseOrder } from "~/store/material/vendorOrderSlice";

import VendorOrderReceive from "./components/VendorOrderReceive";

const columns = [
	{ field: "vendorName", flex: 1, headerName: "Vendor Name" },
	{ field: "erpId", flex: 1, headerName: "ERP ID" },
	{ field: "orderPlacedDate", flex: 1, headerName: "Order Placed", valueFormatter: (value: string) => (value ? stringFormatDate(value) : "") },
	{ field: "purchaseOrderNumber", flex: 1, headerName: "PO Number" },
	{ field: "status", flex: 1, headerName: "Status" },
	{ field: "created", flex: 1, headerName: "Created" },
	{ field: "updated", flex: 1, headerName: "Updated" },
	{ field: "id", flex: 1, headerName: "id" },
	{ field: "siteLocationId", flex: 1, headerName: "Site Location ID" },
	{ field: "tenantId", flex: 1, headerName: "Tenant ID" },
	{
		field: "items",
		flex: 1,
		headerName: "Items",
		valueFormatter: (value: VendorPurchaseOrderItem[]) => (value ? value.map((item) => item.itemName).join(" ") : ""),
	},
];

const columnVisibilityModel = {
	created: false,
	erpId: true,
	id: false,
	items: false,
	orderPlacedDate: true,
	purchaseOrderNumber: true,
	siteLocationId: false,
	status: true,
	tenantId: false,
	updated: false,
	vendorName: true,
};
const columnFilterFields = ["vendorName", "purchaseOrderNumber", "erpId", "items.itemName"];

const cardColumnsRender = [
	{
		field: "orderPlacedDate",
		render: (row: unknown) => {
			const vendorPurchaseOrder = row as VendorPurchaseOrderDocument;
			return stringFormatDate(vendorPurchaseOrder.orderPlacedDate);
		},
	},
];

const VendorPurchaseOrders = () => {
	const authContext = useContext(AuthContext);

	const dispatch = useAppDispatch();
	const editingVendorOrder = useAppSelector((state) => state.material.vendorOrder.editingVendorOrder);
	const siteLocationId = useAppSelector((state) => state.site.location?.id);

	const [currentPage, setCurrentPage] = useState(0);
	const [startCursor, setStartCursor] = useState<string | null | undefined>();
	const [endCursor, setEndCursor] = useState<string | null | undefined>();
	const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "purchaseOrderNumber", sort: "asc" }]);
	const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });
	const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({ page: 0, pageSize: 10 });
	const [rowCount, setRowCount] = useState(0);

	useEffect(() => {
		localStorage.setItem("lastPage", "material/orders");
	}, []);

	const updatePaginationModel = (paginationModel: GridPaginationModel) => {
		if (paginationModel.page === 0) {
			setStartCursor(undefined);
			setEndCursor(undefined);
		} else if (paginationModel.page < currentPage) {
			setStartCursor(vendorPurchaseOrders?.vendorPurchaseOrders?.pageInfo.startCursor || undefined);
			setEndCursor(undefined);
		} else if (paginationModel.page > currentPage) {
			setStartCursor(undefined);
			setEndCursor(vendorPurchaseOrders?.vendorPurchaseOrders?.pageInfo.endCursor || undefined);
		}

		setCurrentPage(paginationModel.page);

		setPaginationModel(paginationModel);
	};

	const grapqlWhere = (): Maybe<VendorPurchaseOrderDocumentFilterInput> => {
		return {
			and:
				filterModel.items.map((filter) => ({
					[filter.field]: { contains: filter.value },
				})) || [],
			or: filterModel.quickFilterValues?.length
				? columnFilterFields.map((field) => {
						//if field has a dot in it, it is a nested field
						//break it up and use the nested field
						if (field.includes(".")) {
							const [parent, child] = field.split(".");
							return { [parent]: { some: { [child]: { contains: (filterModel.quickFilterValues || []).join(" ") } } } };
						} else {
							return { [field]: { contains: (filterModel.quickFilterValues || []).join(" ") } };
						}
						// eslint-disable-next-line no-mixed-spaces-and-tabs
				  })
				: [],
		};
	};

	const handleSelectPurchaseOrder = (row: unknown) => {
		console.log("row", row);
		if (!row) {
			dispatch(setVendorPurchaseOrder(null));
		} else {
			const vendorPurchaseOrder = row as VendorPurchaseOrderDocument;
			dispatch(setVendorPurchaseOrder(vendorPurchaseOrder));
			dispatch(openVendorOrderEdit());
		}
		return;
	};

	const vendorPurchaseOrdersVariables: VendorPurchaseOrdersQueryVariables = {
		after: endCursor,
		before: startCursor,
		first: !startCursor ? paginationModel.pageSize : null, // Adjust number of items fetched as needed
		last: startCursor ? paginationModel.pageSize : null, // Adjust number of items fetched as needed
		order: sortModel.length
			? { [sortModel[0].field]: sortModel[0].sort === "asc" ? SortEnumType.Asc : SortEnumType.Desc }
			: { purchaseOrderNumber: SortEnumType.Asc },
		where: {
			siteLocationId: { eq: siteLocationId || "NOTFOUND" },
			tenantId: { eq: authContext.company?.abbreviation || "NOTFOUND" },
			...grapqlWhere(),
		},
	};
	const {
		data: vendorPurchaseOrders,
		error: vendorPurchaseOrdersError,
		isLoading: vendorPurchaseOrdersLoading,
	} = useVendorPurchaseOrdersQuery(vendorPurchaseOrdersVariables, { pollingInterval: 30000 });

	useEffect(() => {
		if (vendorPurchaseOrders?.vendorPurchaseOrders?.nodes) {
			setRowCount(vendorPurchaseOrders?.vendorPurchaseOrders?.totalCount || 0);
			if (vendorPurchaseOrders?.vendorPurchaseOrders?.totalCount === 1) {
				handleSelectPurchaseOrder(vendorPurchaseOrders?.vendorPurchaseOrders?.nodes[0]);
			}
		}
	}, [vendorPurchaseOrders]);

	return (
		<>
			<SpacedGridContainer>
				<SpacedGridItem maxCols={1} smMargin>
					<DataCardGrid
						cardColumnsRender={cardColumnsRender}
						columnVisibilityModel={columnVisibilityModel}
						columns={columns}
						error={vendorPurchaseOrdersError ? true : false}
						filterModel={filterModel}
						loading={vendorPurchaseOrdersLoading}
						onFilterModelChange={setFilterModel}
						onPaginationModelChange={updatePaginationModel}
						onSelect={handleSelectPurchaseOrder}
						onSortModelChange={setSortModel}
						paginationModel={paginationModel}
						rowCount={rowCount}
						rows={vendorPurchaseOrders?.vendorPurchaseOrders?.nodes || []}
						sortModel={sortModel}
						title="Vendor Purchase Orders"
					/>
				</SpacedGridItem>
			</SpacedGridContainer>
			<ModalDialog
				close={() => {
					return;
				}}
				open={editingVendorOrder}
				title="Receive Purchase Order"
			>
				<VendorOrderReceive />
			</ModalDialog>
		</>
	);
};

export default VendorPurchaseOrders;
