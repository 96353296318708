import { Button, Paper } from "@mui/material";
import { useContext } from "react";

import SFGLogo from "~/assets/SF-Logo-foronwhite.svg";
import SFGLogoDark from "~/assets/SFLogoDark.svg";
import { AuthContext } from "~/auth/AuthProvider";
import SpacedGridContainer from "~/components/SpacedGridContainer";
import SpacedGridItem from "~/components/SpacedGridItem";
import Config from "~/config";

interface ForbiddenProps {
	themeType?: "light" | "dark";
}

const Forbidden = (props: ForbiddenProps) => {
	const authContext = useContext(AuthContext);

	return (
		<SpacedGridContainer>
			<SpacedGridItem maxCols={1} smMargin>
				<h1>Error, Not Authorized</h1>
				<h3>Contact Manager for assistance</h3>
			</SpacedGridItem>
			<SpacedGridItem maxCols={1} smMargin>
				<Button color="secondary" onClick={() => authContext.redirectToLogout()} variant="contained">
					Try Logging in Again
				</Button>
			</SpacedGridItem>
			<SpacedGridItem maxCols={1} smMargin>
				<Paper style={{ padding: "1em", textAlign: "center" }}>
					<img alt="SFG Logo" src={props.themeType === "dark" ? SFGLogoDark : SFGLogo} style={{ maxWidth: "500px", width: "80%" }} />
				</Paper>
			</SpacedGridItem>
			<SpacedGridItem maxCols={1} smMargin>
				<p>Build Version: {Config.buildVersion}</p>
			</SpacedGridItem>
		</SpacedGridContainer>
	);
};
export default Forbidden;
