export const themeOverrides = {
	MuiContainer: {
		styleOverrides: {
			maxWidthXl: {
				"@media (min-width: 1920px)": {
					maxWidth: "1800px",
					width: "100%",
				},
			},
			root: {
				width: "unset",
			},
		},
	},

	MuiDialog: {
		styleOverrides: {
			container: {
				maxWidth: "1200px",
				width: "calc(100% - 16px)",
				//width: "100%",
				//maxWidth: "800px",
			},
			paper: {
				margin: 2,
				maxHeight: "calc(100% - 32px)",
				maxWidth: "unset",
				overflow: "auto",
				width: "100%",
			},
			root: {
				alignItems: "center",
				backgroundImage: "none",
				display: "flex",
				justifyContent: "center",
			},
		},
	},

	MuiDialogActions: {
		styleOverrides: {
			root: {
				"@media (max-width: 600px)": {
					padding: ".5em",
				},
			},
		},
	},

	MuiDialogContent: {
		styleOverrides: {
			root: {
				"@media (max-width: 600px)": {
					padding: ".5em",
				},
			},
		},
	},
	MuiDialogTitle: {
		styleOverrides: {
			root: {
				"@media (max-width: 600px)": {
					padding: ".5em",
				},
			},
		},
	},
	MuiIcon: {
		defaultProps: {
			// Replace the `material-icons` default value.
			baseClassName: "material-symbols-rounded",
		},
	},
	MuiPaper: {
		styleOverrides: {
			elevation1: {
				boxShadow: "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
			},
		},
	},
};

export const themeOverridesDark = {
	MuiContainer: {
		styleOverrides: {
			maxWidthXl: {
				"@media (min-width: 1920px)": {
					maxWidth: "unset",
					width: "unset",
				},
			},
			root: {
				width: "unset",
			},
		},
	},
	MuiDialog: {
		styleOverrides: {
			container: {
				maxWidth: "1200px",
				width: "calc(100% - 16px)",
				//width: "100%",
				//maxWidth: "800px",
			},
			paper: {
				margin: 2,
				maxHeight: "calc(100% - 32px)",
				maxWidth: "unset",
				overflow: "auto",
				width: "100%",
			},
			root: {
				alignItems: "center",
				backgroundImage: "none",
				display: "flex",
				justifyContent: "center",
			},
		},
	},
	MuiIcon: {
		defaultProps: {
			// Replace the `material-icons` default value.
			baseClassName: "material-symbols-rounded",
		},
	},
};
