import { Button, ButtonProps, Dialog, DialogActions, DialogContent, DialogTitle, Icon, IconButton } from "@mui/material";

export interface DialogActionButtons {
	children: React.ReactNode;
	props: ButtonProps;
}

interface ModalDialogProps {
	actions?: DialogActionButtons[] | null;
	children?: React.ReactNode;
	close: () => void;
	open: boolean;
	showCloseButton?: boolean;
	title: string;
}

const ModalDialog: React.FC<ModalDialogProps & React.ComponentProps<typeof Dialog>> = ({
	actions,
	children,
	close,
	open,
	showCloseButton,
	title,
	...otherProps
}) => {
	return (
		<Dialog
			PaperProps={{ sx: { width: "100%" } }}
			closeAfterTransition
			disableEnforceFocus
			disableScrollLock
			onClose={close}
			open={open}
			sx={{ alignItems: "center", justifyContent: "center" }}
			{...otherProps}
		>
			{title && <DialogTitle>{title}</DialogTitle>}
			<DialogContent>
				{showCloseButton && (
					<IconButton aria-label="close" onClick={close} sx={{ color: (theme) => theme.palette.grey[500], position: "absolute", right: 8, top: 8 }}>
						<Icon>close</Icon>
					</IconButton>
				)}
				{children}
			</DialogContent>
			{actions && actions.length > 0 && (
				<DialogActions sx={{ padding: (theme) => `${theme.spacing(2)} ${theme.spacing(3)}` }}>
					{actions.map((action, index) => (
						<Button key={index} variant="contained" {...action.props}>
							{action.children}
						</Button>
					))}
				</DialogActions>
			)}
		</Dialog>
	);
};

export default ModalDialog;
