import { useEffect, useState, useRef } from "react";

//redux
import { useAppSelector } from "~/hooks/reduxHooks";

//material-ui
import { Autocomplete, IconButton, TextField, InputAdornment, Icon, Button, FilterOptionsState } from "@mui/material";

import savedValue from "~/helpers/savedValue";
import { LocationDocument, LocationType } from "~/services/graphql";
import useLocationsPaginatedQuery from "~/services/paginatedHooks/useLocationsPaginated";

interface SelectLocationProps {
	currentLocationId?: string | null;
	handleInputChange: (val: string | null) => void;
	locationTypes: LocationType[];
	saveKey?: string;
}

const SelectLocation = ({ currentLocationId, handleInputChange, locationTypes, saveKey }: SelectLocationProps) => {
	const siteLocation = useAppSelector((state) => state.site.location);
	const [levelLocations, setLevelLocations] = useState<LocationDocument[]>([]);
	const [backToLocation, setBackToLocation] = useState<LocationDocument | null>(null);
	const [locationPath, setLocationPath] = useState<string>("");
	const [locationSelected, setLocationSelected] = useState<LocationDocument | null>(null);

	const [acVal, setAcVal] = useState<LocationDocument | null>(null);
	const [acKey, setAcKey] = useState(0);
	const [acOpen, setAcOpen] = useState(false);
	const acInput = useRef<HTMLInputElement>(null);

	if (!siteLocation) {
		return <p>Error: Site not selected</p>;
	}

	const { allLocations, isError, isLoading } = useLocationsPaginatedQuery(locationTypes);

	let currentLocation = null;

	if (currentLocationId) {
		currentLocation = allLocations.find((l) => l.id === currentLocationId) || null;
	}
	if (saveKey) {
		const savedLocatoinId = savedValue("location-" + saveKey);

		if (savedLocatoinId && !currentLocation) {
			currentLocation = allLocations.find((l) => l.id === savedLocatoinId) || null;
		}
	}
	const isMove = currentLocation !== null && currentLocation.id !== locationSelected?.id;

	const handleLocationChange = (l: LocationDocument | null) => {
		let children: LocationDocument[] = [];

		//if null get top level locations, direct children of siteLocation
		if (l === null) {
			children = allLocations.filter((loc) => loc.parentLocationId === siteLocation.id);
			if (backToLocation) {
				setBackToLocation(null);
			}
			//otherwise get children of current location
		} else {
			children = allLocations.filter((loc) => loc.parentLocationId === l.id);
			//get parent of val and prepend to children so user can go back
			const parent = allLocations.find((l) => l.id === l.parentLocationId) || null;
			// if there is a parent set back to location to parent and prepend to children
			if (parent) {
				setBackToLocation(parent);
				children.length > 0 && children.unshift(parent);
				//otherwise set back to location to null and prepend siteLocation to children
			} else {
				setBackToLocation(null);
				children.length > 0 && children.unshift(siteLocation);
			}
		}
		//set locationPath for display
		getLocationPath(l);
		//if no children set locationSelected and call handleInputChange from parent component
		if (children.length === 0) {
			if (saveKey && l) {
				savedValue("location-" + saveKey, l?.id || "none");
			}
			handleInputChange(l?.id || null);
			setLocationSelected(l);
			//otherwise set levelLocations to children and locationSelected to null
		} else {
			setAcKey(acKey + 1); //rerender autocomplete
			handleInputChange(null);

			setLevelLocations(children);
			setLocationSelected(null);
		}
	};

	const editLocation = () => {
		//get value for parent of locationSelected and call handleLocationChange with that value
		const parent = allLocations.find((l) => l.id === locationSelected?.parentLocationId) || null;
		handleLocationChange(parent);
		setAcOpen(true);
	};

	const getLocationPath = (loc: LocationDocument | null) => {
		if (!loc) {
			setLocationPath("");
			return;
		}
		let path = loc.name;
		let parent = allLocations.find((l) => l.id === loc.parentLocationId);
		while (parent && parent !== undefined) {
			path = `${parent.name} > ${path}`;
			const thisParentId = parent.parentLocationId;
			parent = allLocations.find((l) => l.id === thisParentId);
		}
		setLocationPath(path);
	};

	useEffect(() => {
		handleLocationChange(currentLocation);
	}, [currentLocation, allLocations]);

	const acFilter = (options: LocationDocument[], state: FilterOptionsState<LocationDocument>) => {
		const result = options.filter((option) => `${option.name}`.toLowerCase().indexOf(state.inputValue.toLowerCase()) > -1);
		if (result.length === 1) {
			handleLocationChange(result[0]);
		}
		return result;
	};

	if (isLoading) return <p>Loading...</p>;

	if (isError) {
		return <p>Error: getting Locations</p>;
	}

	if (locationSelected) {
		return (
			<TextField
				disabled
				fullWidth
				label="Location"
				slotProps={{
					input: {
						endAdornment: (
							<InputAdornment position="end">
								<IconButton onClick={editLocation}>
									<Icon>edit</Icon>
								</IconButton>
								{isMove ? (
									<IconButton onClick={() => handleLocationChange(currentLocation)}>
										<Icon>undo</Icon>
									</IconButton>
								) : null}
							</InputAdornment>
						),
						startAdornment: !isMove ? null : (
							<InputAdornment position="start">
								<Button color="info">Move To:</Button>
							</InputAdornment>
						),
					},
				}}
				value={locationPath}
				variant="outlined"
			/>
		);
	}

	return (
		<>
			<Autocomplete
				filterOptions={acFilter}
				getOptionLabel={(option) => {
					if (option.id === backToLocation?.id || option.id === siteLocation.id) {
						return `< Back`;
					} else {
						return `${option.name}`;
					}
				}}
				id="location-input"
				key={acKey}
				onBlur={() => {
					if (!locationSelected) {
						handleLocationChange(null);
						setAcOpen(false);
					}
				}}
				onChange={(_e, v) => {
					setAcVal(null);
					if (v?.id === siteLocation.id) {
						handleLocationChange(null);
						setAcOpen(true);
					} else {
						handleLocationChange(v);
					}
				}}
				onOpen={() => setAcOpen(true)}
				//onSelect={()=> setAcOpen(false)}
				open={acOpen || locationPath !== ""}
				options={levelLocations}
				renderInput={(params) => <TextField {...params} label={locationPath || "Location"} ref={acInput} variant="outlined" />}
				style={{ width: "100%" }}
				value={acVal}

				//isOptionEqualToValue={(option, value) => option.name === value.name}
			/>
		</>
	);
};

export default SelectLocation;
