import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface WorkorderDocument {
	ID: string;
	jobID: string;
	quantity: number;
	quantityProduced: number;
	quantityRemaining: number;
	status: string;
	workorderID: string;
}

interface ProductionCountQueueItem {
	countMultiple: number;
	id: string;
	timestamp: number;
	workorderID: string;
}

interface ProductionCountState {
	addingCustomCount: boolean;
	countMultiple: number;
	lineID: string;
	queue: ProductionCountQueueItem[];
	workorder: WorkorderDocument | undefined;
}

const initialState: ProductionCountState = {
	addingCustomCount: false,
	countMultiple: 1,
	lineID: "",
	queue: [],
	workorder: undefined,
};

export const productionCountSlice = createSlice({
	initialState,
	name: "productionCount",
	reducers: {
		addProductionCountQueue: (state, action: PayloadAction<ProductionCountQueueItem>) => {
			const q = [...state.queue, action.payload];
			q.sort((a, b) => {
				if (a.timestamp === b.timestamp) return 0;
				return a.timestamp < b.timestamp ? 1 : -1;
			});
			state.queue = q;
		},
		closeAddCustomCount: (state) => {
			state.addingCustomCount = false;
		},
		openAddCustomCount: (state) => {
			state.addingCustomCount = true;
		},
		removeProductionCountQueue: (state, action: PayloadAction<ProductionCountQueueItem>) => {
			//remove the item from the queue with the same id then update state
			const queue = state.queue.filter((item) => {
				return item.id !== action.payload.id;
			});
			state.queue = { ...queue };
		},
		updateProductionCountLineID: (state, action: PayloadAction<string>) => {
			state.lineID = action.payload;
		},
		updateProductionCountMultiple: (state, action: PayloadAction<number>) => {
			state.countMultiple = action.payload || 1;
		},
		updateProductionCountQueue: (state, action: PayloadAction<ProductionCountQueueItem>) => {
			//update the countMultiple of the item in the queue with the same id then update state
			let q = state.queue;
			q = q.map((item) => {
				if (item.id === action.payload.id) {
					return action.payload;
				}
				return item;
			});
			q.sort((a, b) => {
				if (a.timestamp === b.timestamp) return 0;
				return a.timestamp < b.timestamp ? 1 : -1;
			});
			console.log("q", q);
			state.queue = { ...q };
		},
		updateProductionCountWorkorder: (state, action: PayloadAction<WorkorderDocument | null>) => {
			state.workorder = action.payload || initialState.workorder;
		},
	},
});

export const {
	addProductionCountQueue,
	closeAddCustomCount,
	openAddCustomCount,
	removeProductionCountQueue,
	updateProductionCountLineID,
	updateProductionCountMultiple,
	updateProductionCountQueue,
	updateProductionCountWorkorder,
} = productionCountSlice.actions;

export default productionCountSlice.reducer;
