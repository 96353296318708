import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useAppDispatch } from "~/hooks/reduxHooks";
import { flatPageMapping } from "~/locationMaps";
import { updateActionLinks } from "~/store/layoutSlice";

// regex match return first match
const getActionLinks = (path: string) => {
	const match = Object.keys(flatPageMapping).find((key) => {
		return new RegExp(path).test(key);
	});
	return match && flatPageMapping[match] ? flatPageMapping[match].actions : [];
};

const useActionMenu = () => {
	const dispatch = useAppDispatch();
	const location = useLocation();

	useEffect(() => {
		const path = location.pathname;
		const actionLinks = getActionLinks(path);
		dispatch(updateActionLinks(actionLinks));
	}, [location, dispatch]);
};

export default useActionMenu;
