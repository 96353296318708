import { GraphQLClient } from "graphql-request";

import Config from "~/config";

const getHeaders = () => {
	const tokenStr = window.localStorage.getItem("appTokens");
	const tokens: TokenSet = tokenStr ? JSON.parse(tokenStr) : {};
	const ret = {
		"App-Install-Id": localStorage.getItem("App-Install-Id") || "",
		"App-Version": Config.releaseVersion,
		Authorization: `Bearer ${tokens["accessToken"]}`,
	};

	return ret;
};

const client = new GraphQLClient(Config.endpoint, {
	credentials: "omit",
	headers: () => getHeaders(),
	mode: "cors",
});

export default client;
