import { Alert, Button, Grid2 as Grid, Icon, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { DataGrid, GridColumnVisibilityModel, GridToolbar, GridToolbarProps, GridValidRowModel, ToolbarPropsOverrides } from "@mui/x-data-grid";
import { Fragment, useEffect, useState } from "react";

import { useAppSelector, useAppDispatch } from "~/hooks/reduxHooks";
import { updateSettings } from "~/store/settingsSlice";

import SelectCard from "./select/SelectCard";
import SelectNative from "./select/SelectNative";
import SpacedGridContainer from "./SpacedGridContainer";
import SpacedGridItem from "./SpacedGridItem";

interface DataCardGridOptions {
	force?: "table" | "card";
	hidePagination?: boolean;
	hideToolbar?: boolean;
	showAddNew?: boolean;
}

interface DataCardGridProps {
	cardColumnsRender?: { field: string; render: (row: unknown) => React.ReactNode }[];
	error?: boolean;
	force?: "table" | "card";
	loading?: boolean;
	onSelect: (row: unknown) => void;
	options?: DataCardGridOptions;
	title?: string;
	toolbar?: Partial<GridToolbarProps & ToolbarPropsOverrides>;
}

const DataCardGrid: React.FC<DataCardGridProps & React.ComponentProps<typeof DataGrid>> = ({
	cardColumnsRender,
	error,
	force,
	loading,
	onSelect,
	options,
	...otherProps
}) => {
	const paginationPageSize = useAppSelector((state) => state.settings.paginationPageSize) || 10;

	const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>(otherProps.columnVisibilityModel || {});
	const [cardData, setCardData] = useState<readonly GridValidRowModel[]>(otherProps.rows || []);
	const [filter, setFilter] = useState("");
	const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: options?.hidePagination ? 100 : paginationPageSize });

	const viewType = useAppSelector((state) => state.layout.viewType);

	// Pagination Logic
	const indexOfLastRecord = (paginationModel.page + 1) * paginationModel.pageSize;
	const indexOfFirstRecord = indexOfLastRecord - paginationModel.pageSize;
	const currentItems = cardData.slice(indexOfFirstRecord, indexOfLastRecord);
	const fromRecord = (paginationModel.page + 1) * paginationModel.pageSize - paginationModel.pageSize + 1;
	const toRecord =
		(paginationModel.page + 1) * paginationModel.pageSize > cardData.length ? cardData.length : (paginationModel.page + 1) * paginationModel.pageSize;

	const itemsPerPageOptions = [10, 25, 50, 100];

	const pageNumbers = [];
	for (let i = 1; i <= Math.ceil(cardData.length / paginationModel.pageSize); i++) {
		pageNumbers.push(i);
	}

	const dispatch = useAppDispatch();

	// Save Pagination Page Size to Redux
	useEffect(() => {
		if (!options?.hidePagination) {
			dispatch(updateSettings({ paginationPageSize: paginationModel.pageSize }));
		}
	}, [paginationModel.pageSize]);

	useEffect(() => {
		setCardData(otherProps.rows || []);
	}, [otherProps.rows]);

	useEffect(() => {
		filterSearch(filter);
	}, [filter, otherProps.rows]);

	const filterSearch = (f: string) => {
		//filter on all fields in otherProps.rows where field is in otherProps.columnVisibilityModel
		if (!f) {
			setCardData(otherProps.rows || []);
			return;
		}
		if (!otherProps.rows) {
			setCardData([]);
			return;
		}
		const newRows = otherProps.rows.filter((row) => {
			for (const col of otherProps.columns) {
				if (columnVisibilityModel[col.field] === false) continue;
				if (row[col.field].toString().toLowerCase().includes(f.toLowerCase())) return true;
			}
			return false;
		});
		if (newRows.length === 1 && cardData.length > 1) {
			onSelect(newRows[0]);
		}
		return setCardData(newRows);
	};

	const addNewButton = options?.showAddNew ? (
		<SpacedGridItem>
			<Button color="secondary" fullWidth onClick={() => onSelect({})} sx={{ marginBottom: 2 }} variant="contained">
				Add New
			</Button>
		</SpacedGridItem>
	) : null;

	const statusComponent = () => {
		if (loading) {
			return (
				<SpacedGridItem maxCols={1}>
					<Alert severity="info">Loading...</Alert>
				</SpacedGridItem>
			);
		}

		if (error) {
			return (
				<SpacedGridItem maxCols={1}>
					<Alert severity="error">Error: see error log</Alert>
				</SpacedGridItem>
			);
		}
		return (
			<SpacedGridItem maxCols={1}>
				<Alert severity="info">No results found</Alert>
			</SpacedGridItem>
		);
	};

	if (force === "table" || (force !== "card" && viewType === "table")) {
		if (otherProps?.rows?.length === 0) {
			return (
				<SpacedGridContainer>
					{addNewButton}
					{statusComponent()}
				</SpacedGridContainer>
			);
		}

		return (
			<>
				{addNewButton}
				<div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
					<DataGrid
						{...{ columns: otherProps.columns, rows: otherProps.rows }}
						columnVisibilityModel={columnVisibilityModel}
						hideFooter={options?.hidePagination === true ? true : false}
						onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
						onPaginationModelChange={setPaginationModel}
						onRowClick={(params) => onSelect(params.row)}
						pageSizeOptions={itemsPerPageOptions}
						paginationModel={options?.hideToolbar ? { page: 0, pageSize: 100 } : paginationModel}
						slotProps={{
							toolbar: {
								showQuickFilter: true,
							},
						}}
						slots={{ toolbar: options?.hideToolbar === true ? null : GridToolbar }}
					/>
				</div>
			</>
		);
	}

	return (
		<SpacedGridContainer alignItems="stretch">
			{addNewButton}
			{options?.hideToolbar === true ? null : (
				<SpacedGridItem maxCols={1} smMargin style={{ marginBottom: "10px", textAlign: "right" }}>
					<TextField
						autoFocus
						fullWidth
						onChange={(e) => {
							setFilter(e.target.value);
						}}
						placeholder="Search..."
						slotProps={{
							input: {
								endAdornment: filter ? (
									<InputAdornment position="end">
										<IconButton
											onClick={() => {
												setFilter("");
											}}
										>
											<Icon>clear</Icon>
										</IconButton>
									</InputAdornment>
								) : null,
								startAdornment: (
									<InputAdornment position="start">
										<Icon>search</Icon>
									</InputAdornment>
								),
							},
						}}
						sx={{ borderColor: "transparent" }}
						value={filter}
						variant="outlined"
					/>
				</SpacedGridItem>
			)}
			{cardData && cardData.length ? (
				<>
					{currentItems.map((row, i) => {
						if (!row) return null;
						return (
							<SpacedGridItem key={i} smMargin>
								<SelectCard
									onClick={() => {
										onSelect(row);
									}}
								>
									{otherProps.columns.map((col, j) => {
										if (otherProps.columnVisibilityModel && otherProps.columnVisibilityModel[col.field] === false) return null;
										return (
											<Fragment key={j}>
												<Grid size={4} style={{ textAlign: "right" }}>
													<Typography color="textSecondary" component="span" variant="inherit">
														{col.headerName} :
													</Typography>
												</Grid>
												<Grid size={8}>{(cardColumnsRender && cardColumnsRender.find((a) => a.field == col.field)?.render(row)) || row[col.field]}</Grid>
											</Fragment>
										);
									})}
								</SelectCard>
							</SpacedGridItem>
						);
					})}
					{options?.hideToolbar === true ? null : (
						<SpacedGridItem maxCols={1} smMargin={true}>
							<Grid alignItems="stretch" container direction="row" spacing={1}>
								<Grid textAlign="center">
									<div style={{ lineHeight: "2.2em" }}>
										<Typography component="span" style={{ paddingRight: ".3rem" }} variant="caption">
											per page
										</Typography>
										<SelectNative
											fullWidth={false}
											onChange={(e) => {
												setPaginationModel({ ...paginationModel, page: 0, pageSize: Number(e.target.value) });
											}}
											options={itemsPerPageOptions.map((pn) => ({ label: String(pn), value: pn }))}
											value={paginationModel.pageSize}
										/>
									</div>
								</Grid>
								<Grid size="grow" textAlign="center">
									<div style={{ lineHeight: "2.2em" }}>
										<Typography component="span" style={{ paddingTop: ".3rem" }} variant="body1">
											{fromRecord}-{toRecord} of {cardData.length}
										</Typography>
									</div>
								</Grid>
								<Grid>
									<IconButton
										color="inherit"
										disabled={paginationModel.page > 0 ? false : true}
										onClick={() => setPaginationModel({ ...paginationModel, page: paginationModel.page - 1 })}
										sx={{ marginRight: 1 }}
									>
										<Icon>chevron_left</Icon>
									</IconButton>

									<IconButton
										color="inherit"
										disabled={paginationModel.page + 1 < pageNumbers.length ? false : true}
										onClick={() => setPaginationModel({ ...paginationModel, page: paginationModel.page + 1 })}
										sx={{ marginRight: 1 }}
									>
										<Icon>chevron_right</Icon>
									</IconButton>
								</Grid>
							</Grid>
						</SpacedGridItem>
					)}
				</>
			) : (
				<>{statusComponent()}</>
			)}
		</SpacedGridContainer>
	);
};

export default DataCardGrid;
