import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

import App from "~/App.tsx";
import AuthProvider from "~/auth/AuthProvider";
import { persistor, store } from "~/store";
import "~/main.css";

ReactDOM.createRoot(document.getElementById("root")!).render(
	//<React.StrictMode>
	<AuthProvider>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</PersistGate>
		</Provider>
	</AuthProvider>
	//</React.StrictMode>
);
