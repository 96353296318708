import { Paper } from "@mui/material";
import { useContext, useEffect } from "react";

import SFGLogo from "~/assets/SF-Logo-foronwhite.svg";
import { AuthContext } from "~/auth";
import SpacedGridContainer from "~/components/SpacedGridContainer";
import SpacedGridItem from "~/components/SpacedGridItem";
import { useAppDispatch } from "~/hooks/reduxHooks";
import { updatePageButtons, updatePageTitle } from "~/store/layoutSlice";

const Home = () => {
	const authContext = useContext(AuthContext);

	//set page title with redux
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(updatePageTitle("Home"));
		dispatch(updatePageButtons([]));
		localStorage.setItem("lastPage", "home");
	}, [dispatch]);

	return (
		<>
			<SpacedGridContainer>
				<SpacedGridItem maxCols={1} smMargin>
					<Paper sx={{ fontFamily: "Montserrat", padding: "1em", textAlign: "center" }}>
						<h1>{authContext.company?.name}</h1>
						<h4>Inventory App</h4>
					</Paper>
				</SpacedGridItem>
				<SpacedGridItem maxCols={1} smMargin>
					<Paper sx={{ padding: "1em", textAlign: "center" }}>
						<img alt="SFG Logo" src={SFGLogo} style={{ maxWidth: "500px", width: "80%" }} />
					</Paper>
				</SpacedGridItem>
			</SpacedGridContainer>
		</>
	);
};

export default Home;
