import { Box, Button, Grid2 as Grid, Icon, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";

import PageHeader from "~/components/PageHeader";
import Config from "~/config";
import Footer from "~/layout/Footer";
import NavMenu from "~/layout/NavMenu";
import TopBar from "~/layout/TopBar";
import Router from "~/router";

const Layout = () => {
	const navigate = useNavigate();

	const Main = styled("main")({
		display: "flex",
		flexDirection: "column",
		flexGrow: 1,
		height: "100%",
		justifyContent: "flex-start",
	});

	return (
		<>
			<TopBar></TopBar>
			<Grid container sx={{ height: "calc(100vh - 64px)" }}>
				<Grid>
					<NavMenu />
				</Grid>
				<Grid size="grow">
					<Main sx={{ padding: { md: 3, xs: 0 } }}>
						<PageHeader />
						<Router />
						<Footer>
							<Grid container spacing={2}>
								<Grid size="grow">
									{window.history.state && window.history.state.idx > 0 && (
										<Button color="inherit" onClick={() => navigate(-1)} startIcon={<Icon>chevron_left</Icon>} variant="text">
											Back
										</Button>
									)}
								</Grid>
								<Grid>
									<Box sx={{ padding: "6px 16px" }}>{Config.releaseVersion}</Box>
								</Grid>
							</Grid>
						</Footer>
					</Main>
				</Grid>
			</Grid>
		</>
	);
};
export default Layout;
