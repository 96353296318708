import { Alert, Chip, Grid2 as Grid, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridColumnVisibilityModel, GridRowId, GridRowParams } from "@mui/x-data-grid";
import { useContext, useState } from "react";

import { AuthContext } from "~/auth";
import SelectCard from "~/components/select/SelectCard";
import SpacedGridItem from "~/components/SpacedGridItem";
import { stringFormatDate } from "~/helpers/timestampFormat";
import { useAppSelector } from "~/hooks/reduxHooks";
import { getWorkOrderChipColor, getWorkOrderStatus } from "~/pages/Count/helpers/workOrderStatusMaps";
import { JobDocument, useWorkOrdersQuery, WorkOrderDocument, WorkOrdersQueryVariables, WorkOrderStatus } from "~/services/graphql";

interface SelectWorkorderProps {
	handleInputChange: (workOrderId: GridRowId, jobId: string) => void;
	jobs: JobDocument[];
	workOrderStatuses: WorkOrderStatus[];
}

const SelectWorkorder = ({ handleInputChange, jobs, workOrderStatuses }: SelectWorkorderProps) => {
	const viewType = useAppSelector((state) => state.layout.viewType);
	const authContext = useContext(AuthContext);

	const jobIds = jobs.map((job) => job.id);

	const columns: GridColDef[] = [
		{ field: "id", flex: 1, headerName: "id" },
		{ field: "jobId", flex: 1, headerName: "jobId" },
		{ field: "erpIp", flex: 1, headerName: "WO#" },
		{ field: "genusCode", flex: 1, headerName: "Genus" },
		{ field: "lineNumber", flex: 1, headerName: "Line" },
		{ field: "item", flex: 1, headerName: "Item" },
		{ field: "upc", flex: 1, headerName: "UPC" },
		{ field: "name", flex: 1, headerName: "Job Name" },
		{ field: "jobStatus", flex: 1, headerName: "jobStatus" },
		{
			field: "workOrderStatus",
			flex: 1,
			headerName: "wostatus",
			valueFormatter: (value) => {
				return getWorkOrderStatus(value as WorkOrderStatus);
			},
		},
		{ field: "jobType", flex: 1, headerName: "jobType" },
		{ field: "locationId", flex: 1, headerName: "locationId" },
		{ field: "unitOfMeasure", flex: 1, headerName: "unitOfMeasure" },
		{ field: "containerQuantity", flex: 1, headerName: "containerQuantity" },
		{ field: "productionQuantity", flex: 1, headerName: "productionQuantity" },
		{ field: "quantityProduced", flex: 1, headerName: "quantityProduced" },
		{ field: "startDate", flex: 1, headerName: "startDate", valueFormatter: (value) => (value ? stringFormatDate(value) : "") },
		{ field: "deliveryDate", flex: 1, headerName: "deliveryDate" },
		{ field: "created", flex: 1, headerName: "created" },
		{ field: "updated", flex: 1, headerName: "updated" },
	];

	const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
		containerQuantity: true,
		created: false,
		deliveryDate: false,
		erpId: true,
		genusCode: true,
		id: false,
		item: true,
		jobId: false,
		jobStatus: false,
		jobType: false,
		lineNumber: true,
		locationId: false,
		name: true,
		productionQuantity: true,
		quantityProduced: true,
		startDate: true,
		unitOfMeasure: true,
		upc: true,
		updated: false,
		workOrderStatus: true,
	});

	const workOrdersVariables: WorkOrdersQueryVariables = {
		first: 50,
		jobId: jobIds,
		tenantId: authContext.company?.abbreviation || "NOTFOUND",
		workOrderStatus: workOrderStatuses,
	};

	const { data: workOrders, error: workOrdersError, isLoading: workOrdersLoading } = useWorkOrdersQuery(workOrdersVariables, { pollingInterval: 30000 });

	if (workOrdersLoading) return <div>Loading work orders...</div>;

	if (workOrdersError) return <div>Error: Getting WorkOrders</div>;

	const getWorkorder = (job: JobDocument): WorkOrderDocument | undefined => {
		return workOrders?.workOrders?.nodes?.find(
			//(wo) => wo.jobId == job.id && (wo.workOrderStatus === WorkOrderStatus.New || wo.workOrderStatus === WorkOrderStatus.InProgress)
			(wo) => wo.jobId == job.id
		);
	};

	const handleRowClick = (params: Maybe<GridRowParams<WorkOrderData>>) => {
		console.log("handleRowClick", params);
		if (!params) return;
		handleInputChange(params.id, params.row.jobId);
	};

	interface WorkOrderData {
		containerQuantity: number;
		created: unknown;
		deliveryDate: unknown;
		erpId: string;
		genusCode: string;
		id: string;
		item: string;
		jobId: string;
		jobStatus: string;
		jobType: string;
		lineNumber: number;
		locationId: string;
		name: string;
		productionQuantity: number;
		quantityProduced: number;
		startDate: unknown;
		unitOfMeasure: string;
		upc: string;
		updated: unknown;
		workOrderStatus: WorkOrderStatus;
	}

	const workOrderData: WorkOrderData[] = jobs
		.map((job) => {
			const workOrder = getWorkorder(job);
			if (!workOrder) return null;
			return {
				containerQuantity: workOrder.containerQuantity,
				created: job.created,
				deliveryDate: job.deliveryDate,
				erpId: job.item.erpId,
				genusCode: job.item.genusCode,
				id: workOrder.id,
				item: job.item.name,
				jobId: job.id,
				jobStatus: job.jobStatus,
				jobType: job.jobType,
				lineNumber: workOrder?.lineNumber || 0,
				locationId: job.locationId,
				name: job.name,
				productionQuantity: workOrder.productionQuantity,
				quantityProduced: workOrder.quantityProduced || 0,
				startDate: workOrder.startDate,
				unitOfMeasure: job.unitOfMeasure,
				upc: job.item.upc,
				updated: job.updated,
				workOrderStatus: workOrder.workOrderStatus,
			};
		})
		.filter((item) => item !== null)
		.sort((a, b) => (String(a.created) > String(b.created) ? 1 : -1));

	if (workOrderData.length === 0) {
		return (
			<SpacedGridItem maxCols={1}>
				<Alert severity="info">No workorders available to select</Alert>
			</SpacedGridItem>
		);
	}

	if (viewType === "table") {
		//create GridColDef based on JobDocument

		return (
			<SpacedGridItem maxCols={1}>
				<div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
					<DataGrid
						columnVisibilityModel={columnVisibilityModel}
						columns={columns}
						onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
						onRowClick={handleRowClick}
						rows={workOrderData}
					/>
				</div>
			</SpacedGridItem>
		);
	}

	return (
		<>
			{workOrderData.map((wod, i) => {
				if (!wod) return null;
				return (
					<SpacedGridItem key={i}>
						<SelectCard
							onClick={() => {
								handleRowClick({ columns: [], id: wod.id, row: wod });
							}}
						>
							<Grid alignItems="stretch" container direction="row" spacing={1}>
								<Grid size={8}>
									<Typography color="textPrimary" component="span" variant="h5">
										{wod.item}
									</Typography>
								</Grid>
								<Grid size={4} style={{ textAlign: "end" }}>
									<Chip color={getWorkOrderChipColor(wod.workOrderStatus)} label={getWorkOrderStatus(wod.workOrderStatus)} size="small" />
								</Grid>
								<Grid size={8}>
									<Typography color="textSecondary" component="span" variant="inherit">
										Workorder:
									</Typography>{" "}
									{wod.erpId}{" "}
								</Grid>
								<Grid size={4} style={{ textAlign: "end" }}>
									<Typography color="textSecondary" component="span" variant="inherit">
										Produced:
									</Typography>{" "}
									{wod.quantityProduced || "?"}
								</Grid>
								<Grid size={8}>
									<Typography color="textSecondary" component="span" variant="inherit">
										UPC:
									</Typography>{" "}
									{wod.upc}
								</Grid>
								<Grid size={4} style={{ textAlign: "end" }}>
									<Typography color="textSecondary" component="span" variant="inherit">
										Target:
									</Typography>{" "}
									{`${wod.containerQuantity}`}
								</Grid>
							</Grid>
						</SelectCard>
					</SpacedGridItem>
				);
			})}
		</>
	);
};

export default SelectWorkorder;
