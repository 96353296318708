import { AppBar, Badge, Box, Icon, IconButton, Toolbar, Typography } from "@mui/material";
import { useMediaQuery } from "@mui/material";

import Config from "~/config";
import { useAppDispatch, useAppSelector } from "~/hooks/reduxHooks";
import PageButtons from "~/layout/PageButtons";
import { toggleDrawer } from "~/store/layoutSlice";

interface TopBarProps {
	children?: JSX.Element;
}

const TopBar = ({ children }: TopBarProps) => {
	const dispatch = useAppDispatch();

	const pageTitle = useAppSelector((state) => state.layout.pageTitle);
	const updateAvailable = useAppSelector((state) => state.layout.updateAvailable);
	const showTopBar = useAppSelector((state) => state.layout.showTopBar);

	const isWideScreen = useMediaQuery("(min-width:" + Config.navDrawerCoverWidth + "px)");

	if (!showTopBar) {
		return <>{children}</>;
	}

	return (
		<AppBar id="appHeader" position="static">
			{Config.environment !== "production" ? (
				<Box
					sx={{
						backgroundColor: "warning.main",
						color: "warning.contrastText",
						fontSize: ".6em",
						fontweight: "bold",
						position: "absolute",
						textAlign: "center",
						width: "100%",
					}}
				>
					&nbsp;{Config.branch} - {Config.buildVersion}&nbsp;
				</Box>
			) : null}
			<Toolbar>
				<IconButton aria-label="open drawer" color="inherit" edge="start" onClick={() => dispatch(toggleDrawer())}>
					<Badge color="error" invisible={!updateAvailable} variant="dot">
						<Icon>menu</Icon>
					</Badge>
				</IconButton>

				<Typography noWrap variant="h6">
					{isWideScreen ? "Inventory" : pageTitle}
				</Typography>
				<PageButtons />
			</Toolbar>
			{children}
		</AppBar>
	);
};

export default TopBar;
