import { useAppDispatch } from "~/hooks/reduxHooks";
import { addError, ErrorDetail } from "~/store/errorSlice";

export const useLogError = () => {
	const logError = (errorDetail: ErrorDetail) => {
		const dispatch = useAppDispatch();
		dispatch(addError(errorDetail));
	};

	return [logError];
};
