import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useAppDispatch } from "~/hooks/reduxHooks";
import { flatPageMapping } from "~/locationMaps";
import { updatePageDescription, updatePageTitle } from "~/store/layoutSlice";

// regex match return first match
const getPageInfo = (path: string) => {
	const match = Object.keys(flatPageMapping).find((key) => {
		//console.log("key", key);
		//return new RegExp(key).test(path);
		return new RegExp(path).test(key);
	});
	//console.log("match", match);
	return flatPageMapping[match || "/home"];
};

const usePageTitle = () => {
	const dispatch = useAppDispatch();
	const location = useLocation();

	useEffect(() => {
		const path = location.pathname;
		//console.log("path", path);
		const pageInfo = getPageInfo(path);
		dispatch(updatePageTitle(pageInfo.title));
		dispatch(updatePageDescription(pageInfo.description || ""));
		//document.title = pageTitle;
	}, [location, dispatch]);
};

export default usePageTitle;
