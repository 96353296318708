import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

export interface ErrorDetail {
	code?: string;
	detail: string | object;
	extra?: string | object;
	id?: string;
	message: string;
	timestamp?: number;
	viewed?: boolean;
}

// Define a type for the slice state
interface ErrorState {
	errors: ErrorDetail[];
	newErrors: number;
}

const initialState: ErrorState = {
	errors: [],
	newErrors: 0,
};

export const errorSlice = createSlice({
	initialState,
	name: "error",
	reducers: {
		addError: (state, action: PayloadAction<ErrorDetail>) => {
			let errs = [...state.errors, { ...action.payload, id: uuidv4(), timestamp: Date.now(), viewed: false }];
			errs.sort((a, b) => {
				a.timestamp = a.timestamp === undefined ? 0 : a.timestamp;
				b.timestamp = b.timestamp === undefined ? 0 : b.timestamp;
				if (a.timestamp === b.timestamp) return 0;
				return a.timestamp < b.timestamp ? 1 : -1;
			});
			//only keep last 100 errors
			errs = errs.slice(0, 100);
			state.errors = errs;
			//set newErrors to number of errors where viewed !== true
			state.newErrors = errs.filter((e) => !e.viewed).length;
		},
		setErrorsViewed: (state) => {
			const errs = [...state.errors];
			errs.forEach((e) => {
				e.viewed = true;
			});
			state.errors = errs;
			state.newErrors = 0;
		},
	},
});

export const { addError, setErrorsViewed } = errorSlice.actions;

export default errorSlice.reducer;
