import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { VendorPurchaseOrderDocument } from "~/services/graphql";

interface VendorOrderReceiveQuantities {
	id: string;
	receivedQuantity: number;
}

interface VendorOrderState {
	editingVendorOrder: boolean;
	locationId?: string;
	vendorOrderReceiveQuantities: VendorOrderReceiveQuantities[];
	vendorPurchaseOrder?: VendorPurchaseOrderDocument;
}

const initialState: VendorOrderState = {
	editingVendorOrder: false,
	vendorOrderReceiveQuantities: [],
};

export const vendorOrderSlice = createSlice({
	initialState,
	name: "materialItem",
	reducers: {
		closeVendorOrderEdit: (state) => {
			state.editingVendorOrder = false;
		},
		openVendorOrderEdit: (state) => {
			//state.viewingVendorOrderLocationQuantities = false;
			state.editingVendorOrder = true;
		},
		resetVendorOrderReceiveQuantities: (state) => {
			state.vendorOrderReceiveQuantities = [];
		},
		setVendorOrderReceiveQuantities: (state, action: PayloadAction<VendorOrderReceiveQuantities>) => {
			if (action.payload.receivedQuantity < 0) {
				action.payload.receivedQuantity = 0;
			}

			const { id, receivedQuantity } = action.payload;
			const index = state.vendorOrderReceiveQuantities.findIndex((v) => v.id === id);
			if (index > -1) {
				state.vendorOrderReceiveQuantities[index].receivedQuantity = receivedQuantity;
			} else {
				state.vendorOrderReceiveQuantities.push(action.payload);
			}
		},
		setVendorPurchaseOrder: (state, action: PayloadAction<VendorPurchaseOrderDocument | null>) => {
			if (action.payload) {
				if (!state.vendorPurchaseOrder) {
					state.vendorPurchaseOrder = action.payload;
				} else {
					Object.assign(state.vendorPurchaseOrder, action.payload);
				}
			} else {
				state.vendorPurchaseOrder = undefined;
			}
		},
	},
});

export const { closeVendorOrderEdit, openVendorOrderEdit, resetVendorOrderReceiveQuantities, setVendorOrderReceiveQuantities, setVendorPurchaseOrder } =
	vendorOrderSlice.actions;

export default vendorOrderSlice.reducer;
