import { useEffect, useState, useRef } from "react";

import { useCountReasonsQuery } from "~/services/endpoints";

//ui
import { Autocomplete, FilterOptionsState, TextField } from "@mui/material";
import { ClientError } from "graphql-request";

import { CountReasonData } from "~/services/graphql";

interface SelectReasonProps {
	countReason?: CountReasonData | null;
	handleInputChange: (val: CountReasonData | null) => void;
}

const SelectReason = ({ countReason, handleInputChange }: SelectReasonProps) => {
	const [allData, setAllData] = useState<CountReasonData[]>([]);

	const [acOpen, setAcOpen] = useState(false);

	const { data, error, isLoading } = useCountReasonsQuery();

	useEffect(() => {
		if (data && data.countReasons && data.countReasons && data.countReasons.length > 0) {
			const nodes = data.countReasons || [];
			setAllData((prevData) => [...prevData, ...nodes]);
		}
	}, [data]);

	const acInput = useRef<HTMLInputElement>(null);

	const acFilter = (options: CountReasonData[], state: FilterOptionsState<CountReasonData>) => {
		const result = options.filter((option) => `${option.reason}`.toLowerCase().indexOf(state.inputValue.toLowerCase()) > -1);
		if (result.length === 1) {
			handleInputChange(result[0]);
			setAcOpen(false);
		}
		return result;
	};

	if (isLoading) return <p>Loading...</p>;
	if (error) {
		const clientError = error as ClientError;
		return <p>Error: {clientError.message}</p>;
	}

	return (
		<Autocomplete
			filterOptions={acFilter}
			getOptionLabel={(option) => (option.reason ? `${option.reason}` : "")}
			id="reason-input"
			isOptionEqualToValue={(option, value) => option.reason === value.reason}
			onChange={(_e, v) => {
				handleInputChange(v);
			}}
			onClose={() => setAcOpen(false)}
			onOpen={() => setAcOpen(true)}
			open={acOpen}
			options={allData}
			renderInput={(params) => <TextField {...params} autoFocus={true} label="reason" ref={acInput} variant="outlined" />}
			style={{ width: "100%" }}
			value={countReason}
		/>
	);
};

export default SelectReason;
