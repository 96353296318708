import { combineReducers } from "@reduxjs/toolkit";

import productionCountReducer from "~/store/count/productionCountSlice";
import setDownReducer from "~/store/count/setDownSlice";

const countReducer = combineReducers({
	productionCount: productionCountReducer,
	setDown: setDownReducer,
	// Add other slice reducers here
});

export default countReducer;
