import { Alert, Button, Grid2 as Grid } from "@mui/material";
import { useContext, useEffect, useRef } from "react";

import { AuthContext } from "~/auth";
import ItemCard from "~/components/ItemCard";
import SpacedGridContainer from "~/components/SpacedGridContainer";
import SpacedGridItem from "~/components/SpacedGridItem";
import { useAppDispatch, useAppSelector } from "~/hooks/reduxHooks";
import { useSnacks } from "~/providers/SnackProvider";
import { MaterialItemDocument, MaterialItemsQueryVariables, useMaterialItemsQuery } from "~/services/graphql";
import { closeVendorOrderEdit, resetVendorOrderReceiveQuantities } from "~/store/material/vendorOrderSlice";

import VendorOrder from "./VendorOrder";
import VendorOrderReceiveLine from "./VendorOrderReceiveLine";

const VendorOrderReceive = () => {
	const countRef = useRef<HTMLInputElement>(null);
	const dispatch = useAppDispatch();

	const authContext = useContext(AuthContext);
	const [openSnack] = useSnacks();
	//const vendorPurchaseOrder = useAppSelector((state) => state.material.vendorOrder.vendorPurchaseOrder);

	const siteLocationId = useAppSelector((state) => state.site.location?.id);
	const vendorPurchaseOrder = useAppSelector((state) => state.material.vendorOrder.vendorPurchaseOrder);
	const vendorOrderReceiveQuantities = useAppSelector((state) => state.material.vendorOrder.vendorOrderReceiveQuantities);

	useEffect(() => {
		dispatch(resetVendorOrderReceiveQuantities());
	}, []);

	useEffect(() => {
		setTimeout(() => {
			countRef.current?.focus();
		}, 100);
	}, [countRef.current]);

	const handleSubmit = async () => {
		//initial checks
		const errors = [];

		if (!siteLocationId) {
			errors.push("Site not found");
		}

		if (errors.length > 0) {
			openSnack(errors.join(", "), "warning");
			return;
		}

		console.log("submitting vendor order receive", vendorOrderReceiveQuantities);

		dispatch(closeVendorOrderEdit());
	};

	const handleCancel = () => {
		dispatch(closeVendorOrderEdit());
	};

	const orderLines = vendorPurchaseOrder?.items || [];

	const materialItemsVariables: MaterialItemsQueryVariables = {
		first: 1000, // Adjust number of items fetched as needed
		tenantId: authContext.company?.abbreviation || "NOTFOUND",
		where: {
			id: { in: (orderLines || []).map((item) => item.id) },
		},
	};

	const {
		data: materialItemsQueryData,
		error: materialItemsError,
		isLoading: materialItemsLoading,
	} = useMaterialItemsQuery(materialItemsVariables, { skip: orderLines.length === 0 });

	if (materialItemsLoading) return <div>Loading vendor purchase order</div>;

	if (materialItemsError) return <div>Error: Getting vendor purchase order</div>;

	const materialItems: MaterialItemDocument[] = materialItemsQueryData?.materialItems?.nodes || [];

	return (
		<SpacedGridContainer>
			<VendorOrder />
			{orderLines.length === 0 ? (
				<SpacedGridItem maxCols={1} smMargin>
					<Alert severity="info">No order lines found</Alert>
				</SpacedGridItem>
			) : (
				<>
					{orderLines.map((item, i) => {
						return (
							<SpacedGridItem key={i} maxCols={2} smMargin>
								<ItemCard key={i}>
									<VendorOrderReceiveLine materialItem={materialItems.find((materialItem) => materialItem.id === item.id) || null} orderLine={item} />
								</ItemCard>
							</SpacedGridItem>
						);
					})}
				</>
			)}

			<SpacedGridItem maxCols={1} smMargin>
				<Grid alignItems="stretch" container direction="row" spacing={1}>
					<Grid size="grow">
						<Button fullWidth onClick={() => handleSubmit()} variant="contained">
							Submit
						</Button>
					</Grid>
					<Grid>
						<Button color="inherit" onClick={handleCancel} variant="contained">
							Cancel
						</Button>
					</Grid>
				</Grid>
			</SpacedGridItem>
		</SpacedGridContainer>
	);
};

export default VendorOrderReceive;
