import { Grid2 as Grid, Icon, IconButton, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridColumnVisibilityModel } from "@mui/x-data-grid";
import { useParams } from "react-router-dom";

import SelectCard from "~/components/select/SelectCard";
import SpacedGridContainer from "~/components/SpacedGridContainer";
import SpacedGridItem from "~/components/SpacedGridItem";
import { timestampFormatDayTime } from "~/helpers/timestampFormat";
import { useAppDispatch, useAppSelector } from "~/hooks/reduxHooks";
import { QueueItemState, removeQueueItem } from "~/store/queueSlice";

interface DisplayData {
	count?: number;
	job?: {
		item?: {
			name?: string;
		};
	};
	location?: string;
	workOrder?: {
		erpId?: string;
		lineNumber?: string;
		containerQuantity?: number;
		startDate?: string;
	};
}

const SetDownsCurrent = () => {
	const { workOrderID } = useParams();
	const dispatch = useAppDispatch();
	const queueItems = useAppSelector((state) =>
		state.queue.queueItems.filter((item) => item.operationName === "SetDown" && item.groupKey === (workOrderID || ""))
	);
	const viewType = useAppSelector((state) => state.layout.viewType);

	console.log("queueItems", queueItems);

	if (!queueItems.length) {
		<Typography variant="h6">Set Down Locations</Typography>;

		return <div>No SetDowns</div>;
	}

	if (viewType === "table") {
		const columns: GridColDef[] = [
			{ field: "id", flex: 1, headerName: "id" },
			{ field: "addedTimestamp", flex: 1, headerName: "Date", valueFormatter: (value?: string) => timestampFormatDayTime(Number(value)) },
			{ field: "location", flex: 1, headerName: "Location", valueGetter: (_value, row) => row.displayData?.location || "" },
			{ field: "count", flex: 1, headerName: "Count", valueGetter: (_value, row) => row.displayData?.count || "" },
			{ field: "status", flex: 1, headerName: "Status" },
		];

		const columnVisibility: GridColumnVisibilityModel = {
			Count: true,
			Date: true,
			id: false,
			Location: true,
			Status: true,
		};

		return (
			<SpacedGridItem maxCols={1}>
				<Typography variant="h6">Locations</Typography>
				<div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
					<DataGrid
						columnVisibilityModel={columnVisibility}
						columns={columns}
						disableColumnFilter={true}
						disableColumnMenu={true}
						hideFooter={true}
						rows={queueItems}
					/>
				</div>
			</SpacedGridItem>
		);
	}

	return (
		<>
			<Typography variant="h6">Locations</Typography>
			<SpacedGridContainer>
				{queueItems.map((qi, i) => {
					if (!qi) return null;
					const qii = qi as QueueItemState & { displayData?: DisplayData };

					return (
						<SpacedGridItem key={i}>
							<SelectCard
								onClick={() => {
									return;
								}}
							>
								<Grid alignItems="stretch" container direction="row" spacing={1}>
									<Grid size="grow">
										<Typography color="textPrimary" component="span" variant="inherit">
											{qii.displayData?.location}
										</Typography>
									</Grid>

									{qi.status === "pending" ? (
										<Grid textAlign="end">
											<IconButton onClick={() => dispatch(removeQueueItem(qi))} size="small" style={{ marginRight: 10 }}>
												<Icon>delete</Icon>
											</IconButton>

											<IconButton color="secondary" onClick={() => void 0} size="small">
												<Icon>edit</Icon>
											</IconButton>
										</Grid>
									) : null}
									<Grid size={12}>
										<Typography color="textSecondary" component="span" variant="inherit">
											Quantity: {qii.displayData?.count}
										</Typography>
									</Grid>

									<Grid size={6}>
										<Typography color="textSecondary" component="span" variant="inherit">
											Status: {qi.status}
										</Typography>
									</Grid>
									<Grid size={6} style={{ textAlign: "end" }}>
										<Typography color="textSecondary" component="span" variant="inherit">
											{timestampFormatDayTime(Number(qi.addedTimestamp))}
										</Typography>
									</Grid>
								</Grid>
							</SelectCard>
						</SpacedGridItem>
					);
				})}
			</SpacedGridContainer>
		</>
	);
};

export default SetDownsCurrent;
