import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { JobDocument, WorkOrderDocument } from "~/services/graphql";

interface SetDownState {
	addingSetDown: boolean;
	count: number;
	editingSetDown: string;
	job?: JobDocument;
	locationId?: string;
	workorder?: WorkOrderDocument;
}

const initialState: SetDownState = {
	addingSetDown: false,
	count: 0,
	editingSetDown: "",
};

export const setDownSlice = createSlice({
	initialState,
	name: "setDown",
	reducers: {
		closeAddSetDown: (state) => {
			state.addingSetDown = false;
		},
		closeEditSetDown: (state) => {
			state.editingSetDown = "";
		},
		openAddSetDown: (state) => {
			state.addingSetDown = true;
		},
		openEditSetDown: (state, action: PayloadAction<string>) => {
			state.editingSetDown = action.payload;
		},
		updateSetDownCount: (state, action: PayloadAction<number>) => {
			state.count = action.payload || 0;
		},
		updateSetDownJob: (state, action: PayloadAction<JobDocument | null>) => {
			if (!action.payload) {
				state.job = undefined;
			} else {
				if (!state.job) {
					state.job = action.payload;
				} else {
					Object.assign(state.job, action.payload);
				}
			}
		},
		updateSetDownLocationId: (state, action: PayloadAction<string | null>) => {
			state.locationId = action.payload || undefined;
		},
		updateSetDownWorkorder: (state, action: PayloadAction<WorkOrderDocument | null>) => {
			if (!action.payload) {
				state.workorder = undefined;
			} else {
				if (!state.workorder) {
					state.workorder = action.payload;
				} else {
					Object.assign(state.workorder, action.payload);
				}
			}
		},
	},
});

export const {
	closeAddSetDown,
	closeEditSetDown,
	openAddSetDown,
	openEditSetDown,
	updateSetDownCount,
	updateSetDownJob,
	updateSetDownLocationId,
	updateSetDownWorkorder,
} = setDownSlice.actions;

export default setDownSlice.reducer;
