import { Grid2 as Grid, Typography } from "@mui/material";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "~/auth";
import SelectCard from "~/components/select/SelectCard";
import SpacedGridContainer from "~/components/SpacedGridContainer";
import SpacedGridItem from "~/components/SpacedGridItem";
import { stringFormatDateTime } from "~/helpers/timestampFormat";
import { useAppDispatch, useAppSelector } from "~/hooks/reduxHooks";
import { JobDocument, useWorkOrdersQuery, WorkOrdersQueryVariables, WorkOrderStatus } from "~/services/graphql";
import { updatePageButtons } from "~/store/layoutSlice";

interface ProductionLinesProps {
	jobs: JobDocument[];
}

const ProductionLines = ({ jobs }: ProductionLinesProps) => {
	const numberOfProductionLines = useAppSelector((state) => state.settings.numberOfProductionLines);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const authContext = useContext(AuthContext);

	const jobIds = jobs.map((job) => job.id);

	const navigateToLine = (line: number) => {
		dispatch(updatePageButtons([]));
		return navigate("/count/line/" + String(line));
	};

	useEffect(() => {
		// if there is only 1 line then redirect to that line
		if (numberOfProductionLines === 1) {
			return navigateToLine(1);
		}

		//)
	}, [numberOfProductionLines]);

	useEffect(() => {
		localStorage.setItem("lastPage", "count/lines");
	}, []);

	const workOrdersVariables: WorkOrdersQueryVariables = {
		first: 50,
		jobId: jobIds,
		tenantId: authContext.company?.abbreviation || "NOTFOUND",
		workOrderStatus: [WorkOrderStatus.New, WorkOrderStatus.InProgress],
	};

	const { data, error, isLoading } = useWorkOrdersQuery(workOrdersVariables, { pollingInterval: 30000 });

	if (isLoading) return <div>Loading work orders...</div>;

	if (error) return <div>Error: Getting WorkOrders</div>;
	//if (error) return <Alert severity="info">{error.message}</Alert>;

	//find if there is a workorder assigned to a line

	return (
		<SpacedGridContainer alignItems="stretch">
			{Array.apply(0, Array(numberOfProductionLines)).map(function (_x, i) {
				const productionLine = i + 1;
				const workOrder = data?.workOrders?.nodes?.find((wo) => wo.lineNumber === productionLine);
				const job = jobs.find((job) => job.id === workOrder?.jobId);
				return (
					<SpacedGridItem key={productionLine} maxCols={3} smMargin={false}>
						<SelectCard onClick={() => navigateToLine(productionLine)}>
							<Grid container spacing={1} sx={{ minHeight: "120px" }}>
								<Grid size={12}>
									<Grid container spacing={2}>
										<Grid size={12}>
											<Typography color="textPrimary" component="h5" gutterBottom={false} sx={{ textAlign: "center" }} variant="h5">
												{`Line ${productionLine}`}
											</Typography>
										</Grid>
										{workOrder ? (
											<>
												<Grid size={12}>
													<Typography color="primary" component="h6" variant="h6">
														{job?.item?.name}
													</Typography>
												</Grid>
												<Grid size={3}>
													<b>Workorder:</b>
													<br /> {workOrder.erpId || ""}
												</Grid>
												<Grid size={3}>
													<b>Target</b> <br />
													{workOrder.containerQuantity}
												</Grid>
												<Grid>
													<b>Started</b>
													<br />
													{stringFormatDateTime(String(workOrder.startDate))}
												</Grid>
											</>
										) : (
											<>
												<Grid size={12}>
													<b>No Current Workorder</b>
												</Grid>
											</>
										)}
									</Grid>
								</Grid>
							</Grid>
						</SelectCard>
					</SpacedGridItem>
				);
			})}
		</SpacedGridContainer>
	);
};

export default ProductionLines;
