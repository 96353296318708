import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { CountReasonData, MaterialItemDocument, MaterialItemLocationQuantityDocument } from "~/services/graphql";

interface MaterialItemState {
	count: number;
	editingMaterialItem: boolean;
	locationId?: string;
	materialItemDocument?: MaterialItemDocument;
	materialItemLocationQuantity?: MaterialItemLocationQuantityDocument;
	reason?: CountReasonData;
	viewingMaterialItemLocationQuantities: boolean;
}

const initialState: MaterialItemState = {
	count: -1,
	editingMaterialItem: false,
	viewingMaterialItemLocationQuantities: false,
};

export const materialItemSlice = createSlice({
	initialState,
	name: "materialItem",
	reducers: {
		clearMaterialItemReason: (state) => {
			state.reason = undefined;
		},
		closeMaterialItemEdit: (state) => {
			state.editingMaterialItem = false;
		},
		closeMaterialItemLocationQuantities: (state) => {
			state.materialItemDocument = undefined;

			state.viewingMaterialItemLocationQuantities = false;
		},
		openMaterialItemEdit: (state) => {
			//state.viewingMaterialItemLocationQuantities = false;
			state.editingMaterialItem = true;
		},
		openMaterialItemLocationQuantities: (state) => {
			state.editingMaterialItem = false;
			state.viewingMaterialItemLocationQuantities = true;
		},
		resetMaterialItemLocationQuantity: (state) => {
			state.materialItemLocationQuantity = undefined;
			state.count = -1;
			state.reason = undefined;
			state.locationId = undefined;
		},
		setMaterialItem: (state, action: PayloadAction<MaterialItemDocument>) => {
			state.materialItemDocument = action.payload;
		},
		setMaterialItemLocationQuantity: (state, action: PayloadAction<MaterialItemLocationQuantityDocument>) => {
			if (action.payload) {
				if (!state.materialItemLocationQuantity) {
					state.materialItemLocationQuantity = action.payload;
				} else {
					Object.assign(state.materialItemLocationQuantity, action.payload);
				}
				state.count = Number(action.payload.quantity);
				state.reason = undefined;
				state.locationId = action.payload.locationId;
			}
		},

		/*
		updateMaterialItemLocation: (state, action: PayloadAction<LocationDocument | null>) => {
			if (!action.payload) {
				state.location = undefined;
			} else {
				if (!state.location) {
					state.location = action.payload;
				} else {
					Object.assign(state.location, action.payload);
				}
			}
		},
		*/
		updateMaterialItemCount: (state, action: PayloadAction<number>) => {
			state.count = action.payload || 0;
		},
		updateMaterialItemLocationId: (state, action: PayloadAction<string | null>) => {
			state.locationId = action.payload || undefined;
		},
		updateMaterialItemReason: (state, action: PayloadAction<CountReasonData>) => {
			state.reason = action.payload;
		},
	},
});

export const {
	clearMaterialItemReason,
	closeMaterialItemEdit,
	closeMaterialItemLocationQuantities,
	openMaterialItemEdit,
	openMaterialItemLocationQuantities,
	resetMaterialItemLocationQuantity,
	setMaterialItem,
	setMaterialItemLocationQuantity,
	updateMaterialItemCount,
	updateMaterialItemLocationId,
	updateMaterialItemReason,
} = materialItemSlice.actions;

export default materialItemSlice.reducer;
