import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useAppDispatch } from "~/hooks/reduxHooks";
import { flatPageMapping } from "~/locationMaps";
import { updatePageButtons } from "~/store/layoutSlice";

// regex match return first match
const getPageButtons = (path: string) => {
	const match = Object.keys(flatPageMapping).find((key) => {
		return new RegExp(path).test(key);
	});
	return match && flatPageMapping[match] ? flatPageMapping[match].buttons : [];
};

const usePageButtons = () => {
	const dispatch = useAppDispatch();
	const location = useLocation();

	useEffect(() => {
		const path = location.pathname;
		//console.log("path", path);
		const pageButtons = getPageButtons(path);
		console.log("pageButtons", pageButtons);
		dispatch(updatePageButtons(pageButtons));
	}, [location, dispatch]);
};

export default usePageButtons;
