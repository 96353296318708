/* eslint-disable perfectionist/sort-objects */
interface PageMapping {
	actions: PageButton[];
	buttons: PageButton[];
	children?: {
		[key: string]: PageMapping;
	};
	description?: string;
	icon?: string;
	label: string;
	nav: boolean;
	title: string;
}

const countActionLinks: PageButton[] = [
	{
		iconName: "monitor",
		key: "lines",
		label: "Production Lines",
		link: "/count/lines",
	},
	{
		iconName: "warehouse_outlined",
		key: "setdown",
		label: "Set Down",
		link: "/count/setdown",
	},
];

const materialActionLinks: PageButton[] = [
	{
		iconName: "category",
		key: "items",
		label: "Material Items",
		link: "/material/items",
	},
	{
		iconName: "orders",
		key: "orders",
		label: "Material Orders",
		link: "/material/orders",
	},
];

const countPageMapping: { [key: string]: PageMapping } = {
	"/count/clipboard": {
		actions: countActionLinks,
		buttons: [
			{
				iconName: "close",
				key: "close",
				label: "Close",
				link: "/count",
			},
		],
		label: "Production Clipboard",
		nav: false,
		title: "",
	},
	"/count/lines": {
		actions: countActionLinks,
		buttons: [
			{
				iconName: "assignment",
				key: "clipboard",
				label: "Clipboard",
				link: "/count/clipboard",
			},
		],
		label: "Lines",
		nav: true,
		title: "Production Lines",
	},
	"/count/setdown": {
		actions: countActionLinks,
		buttons: [
			{
				iconName: "assignment",
				key: "clipboard",
				label: "Clipboard",
				link: "/count/clipboard",
			},
		],
		label: "Set Down",
		nav: true,
		title: "Set Down",
	},
};

const materialPageMapping: { [key: string]: PageMapping } = {
	"/material/items": {
		actions: materialActionLinks,
		buttons: [],
		label: "Items",
		nav: true,
		title: "Material Items",
	},
	"/material/orders": {
		actions: materialActionLinks,
		buttons: [],
		label: "Orders",
		nav: true,
		title: "Material Orders",
	},
};

export const systemPageMapping: { [key: string]: PageMapping } = {
	"/account": {
		actions: [],
		buttons: [],
		description: "View account settings.",
		icon: "account_circle",
		label: "Account",
		nav: true,
		title: "Account",
	},
	"/errorLog": {
		actions: [],
		buttons: [],
		description: "The last 100 errors that have occurred in the app are available here.",
		icon: "error",
		label: "Error Log",
		nav: true,
		title: "Error Log",
	},
	"/Forbidden": {
		actions: [],
		buttons: [],
		description: "You could not be authorized to view the requested page.",
		label: "Forbidden",
		nav: false,
		title: "Not Authorized",
	},
	"/settings": {
		actions: [],
		buttons: [],
		description: "Manage app settings.",
		icon: "settings",
		label: "Settings",
		nav: true,
		title: "Settings",
	},
};

export const featurePageMapping: { [key: string]: PageMapping } = {
	"/home": {
		actions: [],
		buttons: [],
		icon: "home",
		label: "Home",
		nav: true,
		title: "",
	},
	"/count": {
		actions: [],
		buttons: [],
		children: countPageMapping,
		description: "View and manage companies.",
		icon: "conveyor_belt",
		label: "Production",
		nav: true,
		title: "Production",
	},
	"/material": {
		actions: [],
		buttons: [],
		children: materialPageMapping,
		icon: "widgets",
		label: "Material",
		nav: true,
		title: "Material",
	},
};

export const flatPageMapping: { [key: string]: PageMapping } = {
	...systemPageMapping,
	...featurePageMapping,
	...countPageMapping,
	...materialPageMapping,
};
