import type { MiddlewareAPI, Middleware } from "@reduxjs/toolkit";

import { isRejectedWithValue } from "@reduxjs/toolkit";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const errorLoggingMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action: any) => {
	if (isRejectedWithValue(action)) {
		action.payload.error = true;
		const endpointName = action?.meta?.arg?.endpointName;
		const errorDetail = {
			code: endpointName || "Unknown",
			detail: action.payload.message,
			message: "Api Error",
		};
		api.dispatch({ payload: errorDetail, type: "error/addError" });
		console.error("APi Error caught by middleware:", action.payload);
		// You can also dispatch another action here to handle the error globally
		// store.dispatch(showGlobalError(action.payload));
	}
	const endpointName = action?.meta?.arg?.endpointName;
	if (endpointName) {
		if (action.payload && action.payload[endpointName]?.errors) {
			action.payload.error = true;
			const errorDetail = {
				code: endpointName,
				detail: action.payload[endpointName]?.errors,
				message: "Api Payload Error",
			};
			api.dispatch({ payload: errorDetail, type: "error/addError" });
			console.error("Api Payload Error caught by middleware:", action.payload);
		}
	}
	return next(action);
};
