import { Paper } from "@mui/material";

import SelectItem from "~/components/select/SelectItem";
import SpacedGridContainer from "~/components/SpacedGridContainer";
import SpacedGridItem from "~/components/SpacedGridItem";

const Items = () => {
	return (
		<>
			<SpacedGridContainer>
				<SpacedGridItem maxCols={1} smMargin>
					<Paper sx={{ fontFamily: "Montserrat", padding: "1em", textAlign: "center" }}>
						<h1>Items</h1>
					</Paper>
				</SpacedGridItem>
				<SpacedGridItem maxCols={3} smMargin>
					<SelectItem />
				</SpacedGridItem>
			</SpacedGridContainer>
		</>
	);
};

export default Items;
