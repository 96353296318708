import { Grid2 as Grid, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridColumnVisibilityModel } from "@mui/x-data-grid";

import SelectCard from "~/components/select/SelectCard";
import SpacedGridContainer from "~/components/SpacedGridContainer";
import SpacedGridItem from "~/components/SpacedGridItem";
import { stringFormatDateTime, timestampFormatDayTime } from "~/helpers/timestampFormat";
import { useAppSelector } from "~/hooks/reduxHooks";
import { QueueItemState, getSortedQueueItems } from "~/store/queueSlice";

interface DisplayData {
	count?: number;
	job?: {
		item?: {
			name?: string;
		};
	};
	location?: string;
	workOrder?: {
		erpId?: string;
		lineNumber?: string;
		containerQuantity?: number;
		startDate?: string;
	};
}

const formatterSetDown = (qi: QueueItemState & { displayData?: DisplayData }) => {
	return (
		<SelectCard
			onClick={() => {
				return;
			}}
		>
			<Grid size={12} style={{ textAlign: "center" }}>
				<Typography color="textSecondary" component="span" variant="h6">
					Set Down
				</Typography>
			</Grid>
			<Grid alignItems="stretch" container direction="row" spacing={1}>
				<Grid size={12}>
					<Typography color="primary" component="h6" variant="h6">
						{qi.displayData?.job?.item?.name}
					</Typography>
				</Grid>
				<Grid size={6}>
					<Typography color="textPrimary" component="span" variant="inherit">
						{qi.displayData?.location}
					</Typography>
				</Grid>
				<Grid size={6} style={{ textAlign: "end" }}>
					<Typography color="textPrimary" component="span" variant="inherit">
						{qi.displayData?.workOrder?.erpId || ""}
					</Typography>
				</Grid>
				<Grid size={6}>
					<Typography color="textPrimary" component="span" variant="inherit">
						Quantity: {qi.displayData?.count}
					</Typography>
				</Grid>

				<Grid size={6} style={{ textAlign: "end" }}>
					<Typography color="textPrimary" component="span" variant="inherit">
						Status: {qi.status}
					</Typography>
				</Grid>
				<Grid size={6}>
					<Typography color="textSecondary" component="span" variant="inherit">
						{timestampFormatDayTime(Number(qi.addedTimestamp))}
					</Typography>
				</Grid>
			</Grid>
		</SelectCard>
	);
};

const formatterProductionLine = (qi: QueueItemState & { displayData?: DisplayData }) => {
	return (
		<SelectCard onClick={() => void 0}>
			<Grid container spacing={1} sx={{ minHeight: "120px" }}>
				<Grid size={12}>
					<Grid container spacing={2}>
						<Grid size={12} style={{ textAlign: "center" }}>
							<Typography color="textSecondary" component="span" variant="h6">
								Production Count
							</Typography>
						</Grid>

						<Grid size={12}>
							<Typography color="primary" component="h6" variant="h6">
								{qi.displayData?.job?.item?.name}
							</Typography>
						</Grid>
						<Grid size={4}>
							<b>Workorder:</b>
							<br /> {qi.displayData?.workOrder?.erpId || ""}
						</Grid>
						<Grid size={4}>
							<b>Line:</b> <br />
							{qi.displayData?.workOrder?.lineNumber}
						</Grid>
						<Grid size={4}>
							<b>Target</b> <br />
							{qi.displayData?.workOrder?.containerQuantity}
						</Grid>
						<Grid size={4}>
							<b>Started</b>
							<br />
							{stringFormatDateTime(String(qi.displayData?.workOrder?.startDate))}
						</Grid>
						<Grid size={4}>
							<b>Produced</b> <br />
							{qi.displayData?.count}
						</Grid>
						<Grid size={4}>
							<b>Status</b> <br />
							{qi.status}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</SelectCard>
	);
};

const CountClipboard = () => {
	const queueItems = useAppSelector((state) => getSortedQueueItems(state));
	const viewType = useAppSelector((state) => state.layout.viewType);

	console.log("queueItems", queueItems);

	if (!queueItems.length) {
		<Typography variant="h6">Set Down Locations</Typography>;

		return <div>No SetDowns</div>;
	}

	if (viewType === "table") {
		const columns: GridColDef[] = [
			{ field: "id", flex: 1, headerName: "id" },
			{ field: "operationName", flex: 1, headerName: "Operation" },
			{
				field: "workOrder",
				flex: 1,
				headerName: "WorkOrder#",
				valueGetter: (_value, row) => row.displayData?.workOrder?.erpId || "",
			},
			{ field: "addedTimestamp", flex: 1, headerName: "Date", valueFormatter: (value?: string) => timestampFormatDayTime(Number(value)) },
			{ field: "location", flex: 1, headerName: "Location", valueGetter: (_value, row) => row.displayData?.location || "" },
			{ field: "count", flex: 1, headerName: "Count", valueGetter: (_value, row) => row.displayData?.count || "" },
			{ field: "status", flex: 1, headerName: "Status" },
		];

		const columnVisibility: GridColumnVisibilityModel = {
			Count: true,
			Date: true,
			id: false,
			Location: true,
			operationName: true,
			Status: true,
			workOrder: true,
		};

		return (
			<SpacedGridItem maxCols={1}>
				<Typography variant="h6">Locations</Typography>
				<div style={{ width: "100%" }}>
					<DataGrid
						autoHeight={true}
						columnVisibilityModel={columnVisibility}
						columns={columns}
						disableColumnFilter={true}
						disableColumnMenu={true}
						hideFooter={true}
						rows={queueItems}
					/>
				</div>
			</SpacedGridItem>
		);
	}

	return (
		<>
			<SpacedGridContainer>
				{queueItems.map((qi) => {
					if (!qi) return null;
					switch (qi.operationName) {
						case "SetDown":
							return formatterSetDown(qi as QueueItemState & { displayData?: DisplayData });
						case "changeWorkOrderQuantityProducedMutation":
							return formatterProductionLine(qi as QueueItemState & { displayData?: DisplayData });
						default:
							return null;
					}
				})}
			</SpacedGridContainer>
		</>
	);
};

export default CountClipboard;
