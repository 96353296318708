import { combineReducers } from "@reduxjs/toolkit";

import materialItemReducer from "~/store/material/materialItemSlice";
import vendorOrderReducer from "~/store/material/vendorOrderSlice";

const materialReducer = combineReducers({
	materialItem: materialItemReducer,
	vendorOrder: vendorOrderReducer,
	// Add other slice reducers here
});

export default materialReducer;
