import { GraphQLClient } from "graphql-request";
import { createContext } from "react";

import Config from "~/config";

interface GraphContext {
	graphClient: GraphQLClient;
}

export const GraphQLContext = createContext<GraphContext>({} as unknown as GraphContext);

interface GraphQLProviderProps {
	children: React.ReactNode;
}

const GraphQLProvider = ({ children }: GraphQLProviderProps) => {
	const getHeaders = () => {
		const tokenStr = window.localStorage.getItem("appTokens");
		const tokens: TokenSet = tokenStr ? JSON.parse(tokenStr) : {};
		const ret = {
			"App-Install-Id": localStorage.getItem("App-Install-Id") || "",
			"App-Version": Config.releaseVersion,
			Authorization: `Bearer ${tokens["accessToken"]}`,
		};

		return ret;
	};

	const contextValue: GraphContext = {
		graphClient: new GraphQLClient(Config.endpoint, {
			credentials: "omit",
			headers: () => getHeaders(),
			mode: "cors",
		}),
	};

	return <GraphQLContext.Provider value={contextValue}>{children}</GraphQLContext.Provider>;
};

export default GraphQLProvider;
