import { Grid2 as Grid, Grid2Props as GridProps } from "@mui/material";

interface SpaceGridItemProps {
	maxCols?: number;
	smMargin?: boolean; //add margin on small screens
}

const SpacedGridItem: React.FC<SpaceGridItemProps & GridProps & React.ComponentProps<typeof Grid>> = ({ children, maxCols, smMargin, ...props }) => {
	maxCols = maxCols || 3;
	let md = 6;
	let lg = 4;

	const mbxs = smMargin ? 1 : 0;

	if (maxCols === 2) {
		md = 6;
		lg = 6;
	}
	if (maxCols === 1) {
		md = 12;
		lg = 12;
	}

	return (
		<Grid m={{ md: 0, xs: mbxs }} size={{ lg: lg, md: md, xs: 12 }} {...props}>
			{children}
		</Grid>
	);
};

export default SpacedGridItem;
